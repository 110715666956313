import React from "react";

import chevrons from "../../assets/images/global/chev_red.svg";

export default function AllButton(props) {
  return (
    <div className="flex gap-[10px] justify-center items-center cursor-pointer">
      {props.customText ? (
        <h1 className="text-red font-bold text-[18px] xl:text-[24px]">
          {props.lang === "th" ? props.customText.TH : props.customText.EN}
        </h1>
      ) : (
        <h1 className="text-red font-bold text-[18px] xl:text-[24px]">
          {props.lang === "th" ? "ทั้งหมด" : "See more"}
        </h1>
      )}
      <img className="w-[18px]" src={chevrons} alt="" />
    </div>
  );
}
