import http from "./../http-common";
import SliderModel from "../../models/components/slider";

// Navbar APIs
// eslint-disable-next-line
export default new (class SliderService {
  // Get all members
  async getImage() {
    return await http
      .get("/home-sliders?populate=deep")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((image) => SliderModel.get(image))
        );

        return data.sort(function (a, b) {
          return a.order - b.order;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
