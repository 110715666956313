import React, { useEffect, useState } from "react";
import mail from "../assets/images/footer/003-mail 1.png";
import fb from "../assets/images/footer/006-facebook 1.png";
import tel from "../assets/images/footer/phone.png";
import calendar from "../assets/images/footer/009-calendar 1.png";
import TemplateService from "../services/components/template";
import { Link } from "react-router-dom";
import logo from "../assets/images/global/logo.png";
import { currentLang } from "../configs/lang";

export default function Footer(props) {
  let [templateData, setTemplateData] = useState([]);
  let lang = currentLang;

  useEffect(() => {
    getTemplateData();
  }, []);

  const getTemplateData = async () => {
    try {
      let res = await TemplateService.getTemplate();
      setTemplateData(res);
    } catch {
      console.error();
    }
  };

  function getExternalLinks(data) {
    const externalLinksObject = data.find(
      (item) => item.name === "external_link"
    );
    return externalLinksObject ? externalLinksObject.external_link : [];
  }

  return (
    <div id="footer">
      <div className="bg-white section border-t-[2px] border-t-gold">
        <div className="!z-[1] navbar-container flex flex-col lg:grid lg:grid-cols-3 gap-[50px] pt-[34px] pb-[27px] flex justify-between items-start">
          <div className="col-span-2">
            <Link to={`/`}>
              <div className="flex divide-x divide-darkGray gap-[19px] py-[2px] items-center">
                <div>
                  <img className="w-[80px]" src={logo} alt="" />
                </div>
                <div className="flex flex-col mt-[15px]">
                  {
                    <p className="text-left capitalize font-bold text-[18px] xl:text-[22px] text-brown text-left leading-7 pl-[19px] whitespace-pre-line">
                      สมาคมพยาบาลแห่งประเทศไทยฯ
                    </p>
                  }

                  {
                    <p className="text-left capitalize text-[16px] xl:text-[20px] text-brown text-left leading-7 pl-[19px] whitespace-pre-line">
                      สำนักงานสาขาภาคตะวันออก
                    </p>
                  }
                </div>
              </div>
            </Link>

            <div className="grid grid-cols-3 mt-[35px] gap-[8px]">
              {templateData &&
                getExternalLinks(templateData).map((item, i) => (
                  <h1
                    key={i}
                    className="text-[20px] text-gold text-left cursor-pointer hover:underline"
                    onClick={() => {
                      window.open(item.href, "_blank");
                    }}
                  >
                    {lang === "th" ? item.label_TH : item.label_EN}
                  </h1>
                ))}
            </div>
          </div>

          <div className="gap-[20px] col-span-1">
            <p className="text-[26px] xl:text-[30px] font-medium text-left">
              {lang === "th" ? "ติดต่อเรา" : "Contact Us"}
            </p>
            <div className="flex flex-col gap-[15px] mt-[20px] pl-[15px]">
              <div
                className="flex gap-[13px] items-center cursor-pointer"
                onClick={() => {
                  window.location.href = templateData[2].link;
                }}
              >
                <img className="w-[26px]" src={mail} alt="" />
                {templateData && templateData[2] && (
                  <p className="whitespace-pre-line text-gold text-[18px]">
                    {lang === "th"
                      ? templateData[2].detail.detail_TH
                      : templateData[2].detail.detail_EN}
                  </p>
                )}
              </div>

              <div
                className="flex gap-[13px] items-center cursor-pointer"
                onClick={() => {
                  window.open(templateData[3].link, "_blank");
                }}
              >
                <img className="w-[26px]" src={fb} alt="" />
                {templateData && templateData[3] && (
                  <p className="whitespace-pre-line text-gold text-[18px]">
                    {lang === "th"
                      ? templateData[3].detail.detail_TH
                      : templateData[3].detail.detail_EN}
                  </p>
                )}
              </div>

              <div className="flex gap-[13px] items-center">
                <img className="w-[26px]" src={tel} alt="" />
                {templateData && templateData[4] && (
                  <p className="whitespace-pre-line text-gold text-[18px]">
                    {lang === "th"
                      ? templateData[4].detail.detail_TH
                      : templateData[4].detail.detail_EN}
                  </p>
                )}
              </div>

              <div className="flex gap-[13px] items-center">
                <img className="w-[26px]" src={calendar} alt="" />
                {templateData && templateData[5] && (
                  <p className="whitespace-pre-line text-gold text-[18px]">
                    {lang === "th"
                      ? templateData[5].detail.detail_TH
                      : templateData[5].detail.detail_EN}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="bg-red w-full text-center text-white text-[10px] xl:text-[12px] py-[3px]">
        © Copyright 2022. All Rights Reserved.
      </p>
    </div>
  );
}
