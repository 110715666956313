import React, { useEffect } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import TextBanner from "../../../components/banner/TextBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { currentLang } from "../../../configs/lang";

export default function MemberInfo() {
  //   let [serviceData, setServiceData] = useState([]);
  //   let [serviceBanner, setServiceBanner] = useState([]);

  let lang = currentLang;

  useEffect(() => {
    window.scrollTo(0, 0);
    // getServiceData();
    // getServiceBanner();
  }, []);


  const bannerText = [
    { name: "service", href: "/service" },
    { name: "Member Information", href: "/service/memberInfo" },
  ];

  return (
    <div id="member-info" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"ข้อมูลสมาชิก"}
        text_en={"Member Information"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[75px] mb-[300px]">
            <div className="flex flex-col lg:flex-row gap-[42px] w-full">
              <div className="w-full lg:w-fit lg:min-w-[326px]">
                <div className="min-h-[300px] lg:h-full bg-lightBg border-[1px] border-lightGray rounded-[12px]" />
              </div>
              <div className="bg-lightBg border-[1px] border-lightGray rounded-[12px] flex flex-col items-start gap-[20px] py-[70px] px-[46px] w-full">
                <h1 className="text-[28px] md:text-[30px] lg:text-[36px]">
                  ชื่อ นามสกุล สมาชิก
                </h1>

                <div className="flex items-center gap-[22px] text-[20px] lg:text-[22px]">
                  <p>เลขบัตรประชาชน</p>
                  <p className="text-gold">x - xxxx - xxxxx - xx - x</p>
                </div>

                <div className="flex items-center gap-[22px] text-[20px] lg:text-[22px]">
                  <p>เลขสมาชิก</p>
                  <p className="text-gold">เลขสมาชิก</p>
                </div>

                <div className="flex items-center gap-[22px] text-[20px] lg:text-[22px]">
                  <p>วันเกิด</p>
                  <p className="text-gold">วัน เดือน ปีเกิด</p>
                </div>
              </div>
            </div>

            <div className="cursor-pointer flex items-center gap-[12px] justify-end mt-[38px]">
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-[24px] cursor-pointer text-red"
              />
              <p className="text-red text-[20px] lg:text-[22px]">
                แก้ไขข้อมูลสมาชิก
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
