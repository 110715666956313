import React, { useEffect, useState } from "react";
// import ImageCard from "../../components/card/ImageCard";
import AllButton from "../../components/button/AllButton";
import { Link, useHistory, useParams } from "react-router-dom";
// import { useState } from "react";
// import timeFunction from "../../functions/getTime";
import TextBanner from "../../components/banner/TextBanner";
import NewsService from "../../services/news-activity/news";
import { currentLang } from "../../configs/lang";
import timeFunction from "../../functions/getTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import ImageCard from "../../components/card/ImageCard";

export default function AssociationDetail() {
  let [galleryData, setGalleryData] = useState([]);
  let [anotherGalleryData, setAnotherGalleryData] = useState([]);
  let { slug } = useParams();
  const history = useHistory();
  let lang = currentLang;

  useEffect(() => {
    window.scrollTo(0, 0);
    getGalleryData();
    getAnotherGalleryData();
    // eslint-disable-next-line
  }, []);

  const getGalleryData = async () => {
    let res = await NewsService.getActivityBySlug(`${slug}`);

    if (res) {
      setGalleryData(res);
    } else {
      window.open(`${process.env.REACT_APP_WEB_URL}/honor`, "_self");
    }
  };

  const getAnotherGalleryData = async () => {
    try {
      let [res] = await NewsService.getActivity({
        page: 1,
        pageSize: 3,
        category: "activity_gallery",
      });

      setAnotherGalleryData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = () => {
    return [
      { name: "Works and Honor", href: "/honor" },
      {
        name: "Activities",
        href: "/honor/activities",
      },
      {
        name: "กิจกรรมอบรมพยาบาล 2565",
      },
    ];
  };

  const openTag = (input) => {
    const name = encodeURIComponent(input);
    history.push(`/honor/activities?galleryQuery=${name}`);
  };

  return (
    <div className="service-info pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={null}
        text_en={"Documents"}
        // banner={newsBanner.banner}
        bannerText={bannerText()}
        lang={lang}
      />
      <div className="section">
        <div className="page-container">
          {/* บริการ แยก */}
          {galleryData.content && (
            <div className="mt-[54px] mb-[100px]">
              <h1 className="text-[28px] xl:text-[32px] text-left mt-[25px]">
                {galleryData.title?.detail_TH}
              </h1>

              <div className="mt-[10px] flex items-center gap-[30px]">
                <p className="text-[16px] xl:text-[18px] font-medium text-left">
                  {timeFunction.getTime(galleryData.createdAt, lang)}
                </p>
                {/* <div className="flex justify-center items-center gap-[12px]">
                <div>
                  <div className="rounded-full w-[10px] h-[10px] xl:w-[14px] xl:h-[14px] bg-lightGray" />
                </div>
                <p className="text-[16px] xl:text-[18px] font-medium text-left">
                  ฝ่ายพยาบาล
                </p>
              </div> */}
              </div>

              <div className="mt-[10px] flex gap-[16px]">
                <p className="text-[12px] xl:text-[14px] font-medium text-left text-blue">
                  {galleryData.tag &&
                    galleryData.tag.map((item, i) => (
                      <span key={i}>
                        <>
                          <span
                            className="cursor-pointer text-blue hover:underline"
                            onClick={() => openTag(item)}
                          >
                            #{item}
                          </span>
                          {i + 1 < galleryData?.tag?.length && ", "}
                        </>
                      </span>
                    ))}
                </p>
              </div>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[35px] mt-[55px] mb-[120px]">
                  {galleryData?.subImage?.map((item, i) => (
                    <span key={i}>
                      <div
                        onClick={() => {
                          if (item) {
                            const imageUrl = `${process.env.REACT_APP_API_URL}${item.attributes.url}`;
                            window.open(imageUrl, "_blank");
                          }
                        }}
                        className="cursor-pointer w-full rounded-[12px] bg-lightGray h-[240px] !bg-cover !bg-center"
                        style={
                          item
                            ? {
                                background: `url(${process.env.REACT_APP_API_URL}${item.attributes.url})`,
                              }
                            : { background: `#D9D9D9` }
                        }
                      />
                    </span>
                  ))}
                </div>

                <div
                  className="text-left"
                  onClick={() => {
                    window.open(galleryData.content?.drive?.href, "_blank");
                  }}
                >
                  <span className="flex items-center gap-[10px]">
                    สามารถ Download รูปกิจกรรมทั้งหมดได้ที่:{" "}
                    <span className="text-gold cursor-pointer underline flex items-center gap-[10px]">
                      {galleryData.content.drive.label_TH}
                      <FontAwesomeIcon
                        icon={faFolder}
                        className="text-[20px] cursor-pointer"
                      />
                    </span>
                  </span>
                </div>

                <hr className="my-[36px] h-[2px] w-full bg-darkGray" />

                {anotherGalleryData && (
                  <div>
                    <div className="flex justify-between">
                      <h1 className="text-[28px] xl:text-[32px] text-left">
                        ประมวลภาพกิจกรรมอื่นๆ
                      </h1>
                      <Link to={`/honor/activities`}>
                        <AllButton lang={lang} />
                      </Link>
                    </div>

                    {anotherGalleryData && (
                      <div
                        id="image-activity"
                        className="grid grid-cols-3 lg:flex-row gap-[30px] mt-[68px]"
                      >
                        {anotherGalleryData.map((item, i) => (
                          <ImageCard
                            data={item}
                            key={i}
                            lang={lang}
                            type="pride"
                            image={item.image[0]?.attributes}
                            subImage={item.subImage}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
