// eslint-disable-next-line
export default new (class PrideNewsModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.slug = data.attributes.slug || "";
    model.category = data.attributes.pride.category || "";
    model.subcategory = data.attributes.pride.subcategory || "";

    model.title = {
      detail_EN: data.attributes.pride.event_EN || "",
      detail_TH: data.attributes.pride.event_TH || "",
    };

    model.description = {
      detail_EN: data.attributes.pride.detail?.detail_EN || "",
      detail_TH: data.attributes.pride.detail?.detail_TH || "",
    };

    model.tag = data.attributes.pride.tag?.map((tag) => tag.tag) || [];

    if (
      data.attributes.pride.cover_image.length > 0 &&
      data.attributes.pride.cover_image[0].image.data
    ) {
      model.image = data.attributes.pride.cover_image[0].image.data;
    } else {
      model.image = "";
    }

    model.content = data.attributes.pride || {};
    model.createdAt = data.attributes.createdAt || "";
    model.publishedAt = data.attributes.publishedAt || "";

    return model;
  }
})();
