import React, { useEffect, useState } from "react";
// import ServiceService from "../../services/service";
// import BannerService from "../../services/banner";
import MemberService from "../../../services/member/member";
import { useLocation, useHistory } from "react-router-dom";
import TextBanner from "../../../components/banner/TextBanner";
// import CustomButton from "../../../components/button/CustomButton";
import { currentLang } from "../../../configs/lang";

export default function MemberChecking() {
  // eslint-disable-next-line
  let [memberData, setMemberData] = useState({});
  let [memberInput, setMemberInput] = useState("");
  // eslint-disable-next-line
  let [search, setSearch] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let lang = currentLang;

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");

  const searchMember = async (input = memberInput) => {
    try {
      const [firstName, lastName] = input.split("-");
      let res = await MemberService.searchMember(firstName, lastName);
      setMemberData(res);
      setSearch(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (name) {
      setMemberInput(name);
      searchMember(name);
    }
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line
  const handleInputChange = (event) => {
    setMemberInput(event.target.value);
  };

  const bannerText = [
    { name: "service", href: "/service" },
    { name: "Member Check", href: "/service/member_check" },
  ];

  const specialData = [
    "ทุนสนับสนุนการศึกษาสำหรับนิสิต/นักศึกษาพยาบาลดีเด่น",
    "ทุนสนับสนุนการทำวิจัย เวทีเผยแพร่ผลงานวิชาการ/วิจัย",
    "สวัสดิการสมาชิกเมื่อเจ็บป่วย / เสียชีวิต",
    "สวัสดิการสมาชิกเมื่อประสบภัยพิบัติ / สาธารณภัย",
    "เข้าร่วมการพัฒนาวิชาการ / วิชาชีพที่จัดโดยสมาคมพยาบาลฯ",
    "ได้รับการเสนอชื่อเพื่อคัดเลือกพยาบาลดีเด่น",
    "เข้าร่วมงานเชิดชูเกียรติเมื่อเกษียณอายุราชการที่จัด โดยสมาคมพยาบาลฯ",
  ];

  return (
    <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"ตรวจสอบสมาชิกภาพ"}
        text_en={"Member Check"}
        // banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[75px] mb-[300px]">
            {/* <h1 className="text-[28px] xl:text-[32px] text-left">
              ตรวจสอบสมาชิกภาพ
            </h1> */}

            {/* <p className="text-[20px] text-left font-normal mt-[16px] mb-[35px]">
              <span className="text-gold">คำแนะนำ:</span> กรุณากรอก ชื่อของท่าน
              ตามด้วย - และกรอกนามสกุล ตัวอย่าง วันดี-ณ อยุธยา, พิศมัย-สมสกุล
              เป็นต้น
            </p> */}

            {/* <div className="flex flex-col gap-[30px]">
              <input
                className="w-full font-normal placeholder-lightGray2 text-[24px] xl:text-[40px] font-normal rounded-[8px] border-[1px] border-lightGray2 p-[12px] xl:p-[30px]"
                placeholder="กรุณากรอก ชื่อ-นามสกุล (ตัวอย่าง วันดี-ณ อยุธยา)"
                value={memberInput}
                onChange={handleInputChange}
              />
              <CustomButton
                hasIcon
                text={"ตรวจสอบ"}
                onClick={() => {
                  searchMember();
                }}
              />
            </div> */}

            {/* {search && (
              <div className="mt-[30px] flex flex-col">
                <div className="col-span-4 w-full flex flex-col items-start gap-[20px] p-[20px] xl:p-[44px] bg-lightBg border-[1px] border-lightGray rounded-[12px]">
                  <p className="text-[20px] xl:text-[22px] text-darkGray">
                    ผลการตรวจสอบ :{" "}
                  </p>
                  {memberData?.id ? (
                    <div className="text-left flex flex-col gap-[30px]">
                      <p className="text-[28px] xl:text-[34px]">
                        คุณเป็นสมาชิกสมาคมฯ
                      </p>
                      <div className="flex flex-col gap-[15px]">
                        <div className="gap-[22px] grid grid-cols-3 items-start">
                          <p className="col-span-1 text-[20px] xl:text-[22px] text-left">
                            ชื่อ - นามสกุล
                          </p>
                          <p className="col-span-2 text-[20px] xl:text-[22px] text-gold text-left">
                            {memberData?.name} {memberData?.surname}
                          </p>
                        </div>

                        <div className="gap-[22px] grid grid-cols-3 items-start">
                          <p className="col-span-1 text-[20px] xl:text-[22px] text-left">
                            เลขสมาชิก
                          </p>
                          <p className="col-span-2 text-[20px] xl:text-[22px] text-gold text-left">
                            {memberData?.member_id}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-left flex flex-col gap-[20px]">
                      <p className="text-[28px] xl:text-[34px]">
                        <span className="text-red">ไม่พบข้อมูล</span>
                        สมาชิกสมาคมฯ
                      </p>
                      <p className="col-span-1 text-[20px] xl:text-[22px] text-left">
                        กรุณาตรวจสอบ ชื่อ หรือ นามสกุล ของคุณอีกครั้ง
                      </p>
                    </div>
                  )}
                  <div className="mt-[20px] flex flex-col gap-[12px] items-start">
                    <p className="text-[20px] xl:text-[22px] text-darkGray">
                      หมายเหตุ :
                    </p>
                    <p className="text-[16px] xl:text-[18px] text-left font-normal">
                      สำหรับการเข้าสู่ระบบครั้งแรก กรุณากรอกหมายเลขสมาชิก
                      ในช่องรหัสผ่าน จากนั้นท่านสามารถเปลี่ยนรหัสผ่านได้ภายหลัง
                      หากมีข้อสงสัย ติดต่อ{" "}
                      <span className="text-gold">eastnurse.org@gmail.com</span>
                    </p>
                  </div>
                </div>
              </div>
            )} */}

            {/* <hr className="border-t-[1px] border-gold my-[54px]" /> */}

            {/* <h1 className="text-[28px] xl:text-[36px] xl:text-[32px] text-left text-darkGray mb-[40px]">
              เริ่มใช้งานได้ เร็วๆ นี้
            </h1> */}

            <div className="flex flex-col items-start gap-[33px]">
              <h1 className="text-[28px] xl:text-[36px]">
                สิทธิพิเศษหรือสวัสดิการ สำหรับสมาชิก
              </h1>
              <div className="flex flex-col gap-[25px]">
                {specialData.map((item, i) => (
                  <div key={i} className="flex items-center gap-[18px]">
                    <div className="w-[18px] h-[18px] rounded-full bg-gold" />
                    <h1 className="text-[22px] xl:text-[24px]">{item}</h1>
                  </div>
                ))}
              </div>
              <p className="font-normal text-[20px] xl:text-[22px]">
                <span
                  className="font-semibold text-gold cursor-pointer underline"
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(
                      `/service/service_request/member-registration`
                    );
                  }}
                >
                  สมัครสมาชิกสมาคมพยาบาลแห่งประเทศไทยฯ
                </span>{" "}
                เพื่อรับสิทธิพิเศษทั้งหมดทันที !
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
