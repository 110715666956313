function getTime(date, lang) {
  var getDate = new Date(date);

  const result = getDate.toLocaleDateString(lang === "th" ? "th-TH" : "en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return String(result) + (lang === "th" ? " น." : "");
}

function getDay(date, lang) {
  var getDate = new Date(date);

  const result = getDate.toLocaleDateString(lang === "th" ? "th-TH" : "en-EN", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return String(result);
}

function getActivityTime(lang, start_date, end_date, showYear) {
  var getStartDate = new Date(start_date);
  var getEndDate = new Date(end_date);

  const monthNames = [
    lang === "th" ? "ม.ค." : "Jan",
    lang === "th" ? "ก.พ." : "Feb",
    lang === "th" ? "มี.ค." : "Mar",
    lang === "th" ? "เม.ย." : "Apr",
    lang === "th" ? "พ.ค." : "May",
    lang === "th" ? "มิ.ย." : "Jun",
    lang === "th" ? "ก.ค." : "Jul",
    lang === "th" ? "ส.ค." : "Aug",
    lang === "th" ? "ก.ย." : "Sep",
    lang === "th" ? "ต.ค." : "Oct",
    lang === "th" ? "พ.ย." : "Nov",
    lang === "th" ? "ธ.ค." : "Dec",
  ];

  var s_month = monthNames[getStartDate.getMonth()];
  var s_day = getStartDate.getUTCDate();
  var s_year = getStartDate.getUTCFullYear();
  var s_hour = getStartDate.getHours();
  var s_minutes = getStartDate.getMinutes();

  var startHour =
    ("0" + s_hour).slice(-2) +
    "." +
    ("0" + s_minutes).slice(-2) +
    (lang === "th" ? " น." : "");

  if (!showYear) {
    s_year = "";
  }

  if (end_date) {
    var e_month = monthNames[getEndDate.getMonth()];
    var e_day = getEndDate.getUTCDate();
    var e_year = getEndDate.getUTCFullYear();
    var e_hour = getEndDate.getHours();
    var e_minutes = getEndDate.getMinutes();

    startHour =
      ("0" + s_hour).slice(-2) +
      "." +
      ("0" + s_minutes).slice(-2) +
      " - " +
      ("0" + e_hour).slice(-2) +
      "." +
      ("0" + e_minutes).slice(-2) +
      "  น.";

    if (!showYear) {
      e_year = "";
    }

    if (s_day === e_day && s_month === e_month && s_year === e_year) {
      return s_day + " " + s_month + " " + s_year;
    } else if (s_month === e_month && s_year === e_year) {
      return s_day + "-" + e_day + " " + s_month + " " + s_year;
    } else if (s_year === e_year) {
      return s_day + " " + s_month + "-" + e_day + " " + e_month + " " + s_year;
    } else {
      return (
        s_day +
        " " +
        s_month +
        " " +
        s_year +
        "-" +
        e_day +
        " " +
        e_month +
        " " +
        e_year
      );
    }
  }

  return [s_day, s_month, s_year, startHour]; //[0] : day, [1] : month, [2] : year, [3] : time
}

function getTodayDate() {
  const today = new Date();

  let day = today.getDate();
  let month = today.getMonth() + 1;
  const year = today.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${day}/${month}/${year}`;
}

function getPrideData(dateString, lang = "th") {
  const date = new Date(dateString);

  const months = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    th: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  };

  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year =
    lang === "th" ? date.getUTCFullYear() + 543 : date.getUTCFullYear();

  return `${day} ${months[lang][month]} ${year}`;
}

const timeFunction = {
  getTime,
  getActivityTime,
  getDay,
  getTodayDate,
  getPrideData,
};

export default timeFunction;
