import React, { useEffect, useState } from "react";
import AboutUsService from "../../services/about-us/about-us";
// import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import StaffCard from "../../components/card/StaffCard";
import { currentLang } from "../../configs/lang";

export default function President() {
  let lang = currentLang;
  let [presidentData, setPresidentData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await AboutUsService.getPresidentData();
      setPresidentData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "President of the Association", href: "/abouts/association" },
  ];

  const getSortedData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        detail: item.detail.sort((a, b) => a.order - b.order),
      };
    });
  };

  return (
    <>
      {presidentData && (
        <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
          <TextBanner
            text_th={"ทำเนียบนายกสมาคม"}
            text_en={"President of the Association"}
            bannerText={bannerText}
            lang={lang}
          />

          <div className="section">
            <div className="page-container">
              {/* บริการ */}
              <div className="mt-[75px] mb-[300px]">
                <div className="section">
                  <p className="text-[26px] lg:text-[34px] text-brown max-w-[700px]">
                    {lang === "th"
                      ? presidentData.section_title_TH
                      : presidentData.section_title_EN}
                  </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[24px] mt-[45px]">
                  {getSortedData(presidentData.content).map((item, i) => (
                    <div key={i}>
                      <StaffCard
                        data={item.detail[0]}
                        lang={lang}
                        type="president"
                        image={item.image.data.attributes}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
