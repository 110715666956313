import React, { useEffect, useState } from "react";
import PageBanner from "../../components/banner/PageBanner";
import AboutUsService from "../../services/about-us/about-us";
import BannerService from "../../services/components/banner";
import { currentLang } from "../../configs/lang";
import mail from "../../assets/images/footer/003-mail 1.png";

export default function ContactUs() {
  // eslint-disable-next-line
  let [contactUsData, setContactUsData] = useState([]);
  // eslint-disable-next-line
  let [contactUsBanner, setContactUsBanner] = useState([]);
  let lang = currentLang;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAboutUsData();
    getContactUsBanner();
    // eslint-disable-next-line
  }, []);

  const getAboutUsData = async () => {
    try {
      let res = await AboutUsService.getContactData();
      setContactUsData(res);
    } catch {
      console.error();
    }
  };

  const getContactUsBanner = async () => {
    try {
      let res = await BannerService.getBanner('contact');
      setContactUsBanner(res);
    } catch {
      console.error();
    }
  };

  const MapComponent = ({ mapUrl = "" }) => {
    return (
      <div>
        <iframe
          title="contact-map"
          src={mapUrl}
          width="100%"
          height="450"
          // style="border:0;"
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  };

  const bannerText = [{ name: "Contact us", href: "/contact" }];

  return (
    <div
      id="contact"
      className="pt-[100px] sm:pt-[135px] lg:pt-[0px] pb-[100px]"
    >
      <PageBanner
        text_th={"ติดต่อเรา"}
        text_en={"Contact"}
        banner={contactUsBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      {contactUsData && (
        <div className="section">
          <div className="page-container">
            <div className="py-[79px] grid grid-cols-1 md:grid-cols-2 gap-[60px]">
              <div>
                <p className="text-left text-[28px] xl:text-[32px]">
                  แผนที่สถานที่ปฎิบัติงาน
                </p>

                <div className="mt-[12px]">
                  <MapComponent mapUrl={contactUsData.map?.title?.detail_TH} />
                </div>

                <p className="text-left text-[28px] xl:text-[32px] mt-[63px]">
                  ช่องทาง Social Media ของสมาคมฯ
                </p>

                <div className="grid grid-cols-3 items-center mt-[35px]">
                  {contactUsData?.social?.content &&
                    contactUsData?.social.content.map((social, index) => (
                      <div
                        key={index}
                        className="section flex-col gap-[10px] cursor-pointer"
                        onClick={() => {
                          window.open(social.link, "_blank");
                        }}
                      >
                        <div
                          className="!bg-cover !bg-center rounded-full bg-lightGray w-[80px] h-[80px]"
                          style={{
                            background:
                              social &&
                              social.image &&
                              `url(${process.env.REACT_APP_API_URL}${social.image.data[0].attributes.url}), #D9D9D9`,
                          }}
                        ></div>
                        <p className="text-[20px]">{social.detail_th}</p>
                      </div>
                    ))}
                </div>
              </div>

              <div>
                <p className="text-left text-[28px] xl:text-[32px]">
                  สถานที่ปฏิบัติงาน
                </p>

                <div className="mt-[17px] text-left flex flex-col gap-[50px]">
                  {contactUsData?.locations?.content &&
                    contactUsData.locations?.content?.map((social, index) => (
                      <div key={index}>
                        <div className="text-[18px] xl:text-[24px]">
                          {social.subTitle_TH}
                        </div>

                        <div className="mt-[16px] flex flex-col gap-[70px]">
                          {social.content &&
                            social.content.map((item, idx) => (
                              <div key={idx}>
                                <p className="text-[20px] whitespace-pre-line">
                                  {item.detail_TH}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>

                <p className="text-left text-[28px] xl:text-[32px] mt-[60px]">
                  ช่องทาง E-mail
                </p>

                <div
                  className="flex gap-[13px] items-center"
                  onClick={() => {
                    // window.location.href = templateData[2].link;
                  }}
                >
                  <img className="w-[26px]" src={mail} alt="" />
                  {contactUsData && contactUsData?.email?.title && (
                    <p className="whitespace-pre-line text-[18px] text-gold mt-[13px]">
                      {lang === "th"
                        ? contactUsData?.email?.title.detail_TH
                        : contactUsData?.email?.title.detail_EN}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
