import React, { useEffect, useState } from "react";
import AboutUsService from "../../services/about-us/about-us";
// import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import StaffCard from "../../components/card/StaffCard";
import { currentLang } from "../../configs/lang";

export default function Organization() {
  let lang = currentLang;
  let [organizeData, setOrganizeData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await AboutUsService.getOrganizeData();
      setOrganizeData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "Organization Chart", href: "/abouts/broading" },
  ];

  const getFilteredDataByLevel = (data, level = 1) => {
    return data.filter((item) =>
      item.detail.some((detail) => detail.level === level)
    );
  };

  return (
    <>
      {organizeData && (
        <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
          <TextBanner
            text_th={"โครงสร้างคณะกรรมการ"}
            text_en={"Organization Chart"}
            // banner={serviceBanner.banner}
            bannerText={bannerText}
            lang={lang}
          />

          <div className="section">
            <div className="page-container">
              {/* บริการ */}
              <div className="mt-[75px] mb-[300px]">
                <div className="section">
                  <p className="text-[26px] lg:text-[34px] text-brown max-w-[900px]">
                    {lang === "th"
                      ? organizeData.section_title_TH
                      : organizeData.section_title_EN}
                  </p>
                </div>

                <div className="flex flex-col items-center">
                  {/* level 1 */}
                  <div className="grid grid-cols-1 gap-[24px] mt-[45px]">
                    {getFilteredDataByLevel(organizeData.content, 1).map(
                      (item, i) => (
                        <div key={i}>
                          <StaffCard
                            width="370px"
                            data={item.detail[0]}
                            lang={lang}
                            type="president"
                            image={item.image.data.attributes}
                          />
                        </div>
                      )
                    )}
                  </div>

                  {/* level 2 */}
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-[24px] mt-[45px]">
                    {getFilteredDataByLevel(organizeData.content, 2).map(
                      (item, i) => (
                        <div key={i}>
                          <StaffCard
                            width="370px"
                            data={item.detail[0]}
                            lang={lang}
                            type="president"
                            image={item.image.data.attributes}
                          />
                        </div>
                      )
                    )}
                  </div>

                  {/* level 3 */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[24px] mt-[100px]">
                    {getFilteredDataByLevel(organizeData.content, 3).map(
                      (item, i) => (
                        <div key={i}>
                          <StaffCard
                            data={item.detail[0]}
                            lang={lang}
                            type="president"
                            image={item.image.data.attributes}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
