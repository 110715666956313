import React, { useEffect, useRef, useState } from "react";
// import AllButton from "../../components/button/AllButton";
import TextBanner from "../../components/banner/TextBanner";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";
import Datepicker from "react-tailwindcss-datepicker";
import VerticleCard from "../../components/card/VerticleCard";
import ImageCard from "../../components/card/ImageCard";
// import AllButton from "../../components/button/AllButton";
import NewsService from "../../services/news-activity/news";
import { currentLang } from "../../configs/lang";
import { useLocation } from "react-router-dom";
// import PrideService from "../../services/pride/pride";

export default function Association(props) {
  let lang = currentLang;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let [galleryQuery, setGalleryQuery] = useState(
    searchParams.get("galleryQuery")
  );

  let [activityQuery, setActivityQuery] = useState(
    searchParams.get("activityQuery")
  );

  const bannerText = () => {
    return [
      { name: "Our Prides", href: "/honor" },
      {
        name: "Association's Activity",
        href: "/honor/activities",
      },
    ];
  };

  const pageText = () => {
    return [
      {
        text_th: "กิจกรรมสมาคมฯ",
        text_en: "Activities",
        title_th: "กิจกรรมสมาคมฯ",
        title_en: "Activities",
      },
    ];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  //TYPE :: ACTIVITY
  let [metaActivity, setMetaActivity] = useState({});
  let [activity, setActivity] = useState([]);

  const [pageActivity, setPageActivity] = useState(1);
  // eslint-disable-next-line
  const [pageSizeActivity, setPageSizeActivity] = useState(6);
  const [keywordActivity, setKeywordActivity] = useState("");
  // eslint-disable-next-line
  const [sortActivity, setSortActivity] = useState("desc");
  const [dateActivity, setDateActivity] = useState({
    startDate: new Date(
      new Date().getFullYear() - 4,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  useEffect(() => {
    if (activityQuery) {
      handleKeywordChangeActivity(activityQuery);
    }
    // eslint-disable-next-line
  }, [activityQuery]);

  useEffect(() => {
    getActivity();

    // eslint-disable-next-line
  }, [
    keywordActivity,
    dateActivity,
    sortActivity,
    pageActivity,
    pageSizeActivity,
  ]);

  const handleKeywordChangeActivity = (newKeyword) => {
    setActivityQuery(null);
    setKeywordActivity(newKeyword);
  };

  const handleDateChangeActivity = (newValue) => {
    setDateActivity(newValue);
  };

  const getActivity = async () => {
    const startDateObj = new Date(dateActivity.endDate);
    startDateObj.setHours(0, 0, 0, 0);
    const endDateObj = new Date(startDateObj.getTime() + 86399999);
    const endDate = endDateObj.toISOString();

    try {
      let [res, meta] = await NewsService.getActivity({
        ...(keywordActivity && { keyword: keywordActivity }),
        ...(activityQuery && { keyword: activityQuery }),
        startDate: dateActivity.startDate,
        endDate: endDate,
        sort: sortActivity,
        page: pageActivity,
        pageSize: pageSizeActivity,
        category: "social_service",
      });

      setActivity(res);
      setMetaActivity(meta);
    } catch {
      console.error();
    }
  };

  //TYPE :: GALLERY
  let [metaGal, setMetaGal] = useState({});
  let [gallery, setGallery] = useState([]);

  const [pageGal, setPageGal] = useState(1);
  // eslint-disable-next-line
  const [pageSizeGal, setPageSizeGal] = useState(3);
  const [keywordGal, setKeywordGal] = useState("");
  // eslint-disable-next-line
  const [sortGal, setSortGal] = useState("desc");
  const [dateGal, setDateGal] = useState({
    startDate: new Date(
      new Date().getFullYear() - 4,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (galleryQuery) {
      handleKeywordChangeGal(galleryQuery);
      scrollToSection();
    }
    // eslint-disable-next-line
  }, [galleryQuery]);

  useEffect(() => {
    getGallery();

    // eslint-disable-next-line
  }, [keywordGal, dateGal, sortGal, pageGal]);

  const handleKeywordChangeGal = (newKeyword) => {
    setGalleryQuery(null);
    setKeywordGal(newKeyword);
  };

  const handleDateChangeGal = (newValue) => {
    setDateGal(newValue);
  };

  const getGallery = async () => {
    const startDateObj = new Date(dateGal.endDate);
    startDateObj.setHours(0, 0, 0, 0);
    const endDateObj = new Date(startDateObj.getTime() + 86399999);
    const endDate = endDateObj.toISOString();

    try {
      let [res, meta] = await NewsService.getActivity({
        ...(keywordGal && { keyword: keywordGal }),
        ...(galleryQuery && { keyword: galleryQuery }),
        startDate: dateGal.startDate,
        endDate: endDate,
        sort: sortGal,
        page: pageGal,
        pageSize: pageSizeGal,
        category: "activity_gallery",
      });

      setGallery(res);
      setMetaGal(meta);
    } catch {
      console.error();
    }
  };

  return (
    <div id="news-all" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={pageText()[0].text_th}
        text_en={pageText()[0].text_en}
        bannerText={bannerText()}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* ข่าวสาร */}
          {activity && (
            <div id="news-section" className="pt-[54px]">
              <h1 className="text-[28px] xl:text-[32px] text-left capitalize">
                {lang === "th"
                  ? pageText()[0].title_th
                  : pageText()[0].title_en}
              </h1>

              <div className="my-[20px] flex flex-col items-start justify-between gap-[20px] lg:gap-[40px]">
                <div className="w-full gap-[20px] flex flex-col items-center lg:flex-row">
                  <div className="relative w-full">
                    <input
                      defaultValue={keywordActivity}
                      onChange={(e) =>
                        handleKeywordChangeActivity(e.target.value)
                      }
                      placeholder={
                        lang === "th"
                          ? "ค้นหาจาก Keyword"
                          : "Finding by Keyword"
                      }
                      className="w-full font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="text-[25px] cursor-pointer absolute text-darkGray top-[12px] right-[15px] pointer-events-none"
                    />
                  </div>
                  <div className="flex items-center gap-[50px] w-full">
                    <h1 className="text-[20px] whitespace-nowrap">
                      {lang === "th" ? "ช่วงเวลา" : "Time Range"}
                    </h1>
                    <div className="date-picker relative w-full">
                      <Datepicker
                        value={dateActivity}
                        onChange={handleDateChangeActivity}
                      />
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="text-[25px] cursor-pointer absolute text-darkGray top-[14px] left-[15px] pointer-events-none"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    `flex flex-col lg:flex-row items-center gap-[20px] w-full ` +
                    (props.page === "award" ? "justify-between" : "justify-end")
                  }
                >
                  {props.page === "award" && (
                    <div>
                      <p className="text-[16px] xl:text-[18px] flex items-center gap-[22px] items-center flex-wrap">
                        หมวดหมู่ :{" "}
                        <span className="flex items-center gap-[22px] items-center flex-wrap">
                          {[...Array(3)].map((item, i) => (
                            <p
                              key={i}
                              className={
                                `text-brown cursor-pointer ` +
                                (i === 0 && "underline text-gold")
                              }
                            >
                              ข่าวประชาสัมพันธ์
                            </p>
                          ))}
                        </span>
                      </p>
                    </div>
                  )}

                  <div className="flex items-center gap-[50px]">
                    <h1 className="text-[20px] whitespace-nowrap">
                      {lang === "th" ? "เรียงลำดับ" : "Sort By"}
                    </h1>
                    <select
                      name="ordering"
                      className="w-fit font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                      value={sortActivity}
                      onChange={(event) => setSortActivity(event.target.value)}
                    >
                      <option value="desc">
                        {lang === "th"
                          ? "ล่าสุด - เก่าสุด"
                          : "Latest to Oldest"}
                      </option>
                      <option value="asc">
                        {lang === "th"
                          ? "เก่าสุด - ล่าสุด"
                          : "Oldest to Lastest"}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[27px] mt-[38px]">
                {activity.map((item, i) => (
                  <VerticleCard
                    data={item}
                    key={i}
                    lang={lang}
                    type="pride-search"
                    image={item.image[0]}
                  />
                ))}
              </div>

              {activity && activity.length <= 0 && (
                <p className="text-[23px] text-center mt-[40px]">
                  {lang === "th"
                    ? "ไม่พบข้อมูลที่ค้นหา..."
                    : "Not found any news ..."}
                </p>
              )}

              {
                <Pagination
                  length={metaActivity?.pagination?.pageCount ?? ""}
                  selected={pageActivity}
                  function={(i) => setPageActivity(i)}
                />
              }
            </div>
          )}

          {gallery && (
            <div id="gallery-section" className="pt-[54px] pb-[100px]">
              <h1 className="text-[28px] xl:text-[32px] text-left capitalize">
                ประมวลภาพกิจกรรม
              </h1>

              <div className="my-[20px] flex flex-col items-start justify-between gap-[20px] lg:gap-[40px]">
                <div className="w-full gap-[20px] flex flex-col items-center lg:flex-row">
                  <div className="relative w-full">
                    <input
                      ref={sectionRef}
                      defaultValue={keywordGal}
                      onChange={(e) => handleKeywordChangeGal(e.target.value)}
                      placeholder={
                        lang === "th"
                          ? "ค้นหาจาก Keyword"
                          : "Finding by Keyword"
                      }
                      className="w-full font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="text-[25px] cursor-pointer absolute text-darkGray top-[12px] right-[15px] pointer-events-none"
                    />
                  </div>
                  <div className="flex items-center gap-[50px] w-full">
                    <h1 className="text-[20px] whitespace-nowrap">
                      {lang === "th" ? "ช่วงเวลา" : "Time Range"}
                    </h1>
                    <div className="date-picker relative w-full">
                      <Datepicker
                        value={dateGal}
                        onChange={handleDateChangeGal}
                      />
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="text-[25px] cursor-pointer absolute text-darkGray top-[14px] left-[15px] pointer-events-none"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    `flex flex-col lg:flex-row items-center gap-[20px] w-full ` +
                    (props.page === "award" ? "justify-between" : "justify-end")
                  }
                >
                  {props.page === "award" && (
                    <div>
                      <p className="text-[16px] xl:text-[18px] flex items-center gap-[22px] items-center flex-wrap">
                        หมวดหมู่ :{" "}
                        <span className="flex items-center gap-[22px] items-center flex-wrap">
                          {[...Array(3)].map((item, i) => (
                            <p
                              key={i}
                              className={
                                `text-brown cursor-pointer ` +
                                (i === 0 && "underline text-gold")
                              }
                            >
                              ข่าวประชาสัมพันธ์
                            </p>
                          ))}
                        </span>
                      </p>
                    </div>
                  )}

                  <div className="flex items-center gap-[50px]">
                    <h1 className="text-[20px] whitespace-nowrap">
                      {lang === "th" ? "เรียงลำดับ" : "Sort By"}
                    </h1>
                    <select
                      name="ordering"
                      className="w-fit font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                      value={sortGal}
                      onChange={(event) => setSortGal(event.target.value)}
                    >
                      <option value="desc">
                        {lang === "th"
                          ? "ล่าสุด - เก่าสุด"
                          : "Latest to Oldest"}
                      </option>
                      <option value="asc">
                        {lang === "th"
                          ? "เก่าสุด - ล่าสุด"
                          : "Oldest to Lastest"}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              {gallery && (
                <div
                  id="image-activity"
                  className="grid grid-cols-3 lg:flex-row gap-[30px] mt-[68px]"
                >
                  {gallery.map((item, i) => (
                    <ImageCard
                      data={item}
                      key={i}
                      lang={lang}
                      type="pride"
                      image={item.image[0]?.attributes}
                      subImage={item.subImage}
                    />
                  ))}
                </div>
              )}

              {gallery && gallery.length <= 0 && (
                <p className="text-[23px] text-center mt-[40px]">
                  {lang === "th"
                    ? "ไม่พบข้อมูลที่ค้นหา..."
                    : "Not found any news ..."}
                </p>
              )}

              {
                <Pagination
                  length={metaGal?.pagination?.pageCount ?? ""}
                  selected={pageGal}
                  function={(i) => setPageGal(i)}
                />
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
