import React from "react";
import timeFunction from "../../functions/getTime";

export default function ScholarshipCard(props) {
  const subcategoryItems = [
    { name: "ข่าวทุนการศึกษา", value: "scholarship" },
    { name: "ข่าวต่างประเทศ", value: "global_news" },
    { name: "ทุนวิจัย", value: "research_funding" },
  ];

  function getNameByValue(value) {
    const item = subcategoryItems.find((item) => item.value === value);
    return item ? item.name : null;
  }

  function isLinkPage() {
    switch (props.page) {
      case "newsAndActivity/news":
      case "honor/awards/news":
      case "honor/social_activities/news":
      case "service/service_request":
        window.open(
          `${process.env.REACT_APP_WEB_URL}/${props.page}/${props.data.slug}`,
          "_self"
        );
        break;
      default:
        window.open(`${process.env.REACT_APP_WEB_URL}`, "_self");
        break;
    }
  }

  return (
    <div className="flex rounded-[12px] border-[1px] border-lightGray2 w-full min-h-[200px]">
      {props.isImageBox && (
        <div
          onClick={() => isLinkPage()}
          className="rounded-l-[12px] w-[30%] h-full !bg-center !bg-cover"
          style={
            props.data &&
            props.data?.image &&
            props.data?.image?.image?.data !== null
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data?.image[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        />
      )}
      <div className="flex flex-col gap-[10px] py-[10px] px-[10px] md:py-[27px] md:px-[32px] w-full justify-between">
        <div>
          <h1
            className="w-full lg:w-[70%] line-clamp-2 cursor-pointer text-[22px] xl:text-[24px] text-left"
            onClick={() => isLinkPage()}
          >
            {props.lang === "th"
              ? props.data?.title?.detail_TH
              : props.data?.title?.detail_EN}
          </h1>

          {props.showCat && (
            <p className="text-[14px] xl:text-[16px] text-left mt-[9px]">
              {getNameByValue(props.data?.subcategory)}
            </p>
          )}

          {(props.page === "honor/awards/news" ||
            props.page === "honor/social_activities/news") && (
            <p className="text-[16px] xl:text-[18px] text-left mt-[10px]">
              {props.data &&
                timeFunction.getPrideData(props.data?.createdAt, props.lang)}
            </p>
          )}

          {(props.page === "newsAndActivity/news") && (
            <p className="text-[16px] xl:text-[18px] text-left mt-[10px]">
              {props.data &&
                timeFunction.getTime(props.data?.createdAt, props.lang)}
            </p>
          )}

          <p
            className="mt-[10px] line-clamp-2 text-[12px] xl:text-[14px] sarabun text-left w-full lg:w-[70%]"
            dangerouslySetInnerHTML={{
              __html:
                props.lang === "th"
                  ? props.data?.description?.detail_TH
                  : props.data?.description?.detail_EN,
            }}
          ></p>
        </div>

        <p
          className="text-orange text-[12px] xl:text-[14px] cursor-pointer font-bold text-right"
          onClick={() => isLinkPage()}
        >
          อ่านเพิ่มเติม
        </p>
      </div>
    </div>
  );
}
