import React, { useEffect, useState } from "react";
// import AllButton from "../../components/button/AllButton";
import TextBanner from "../../components/banner/TextBanner";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { useParams } from "react-router-dom";
import { journalSize } from "../../configs/pageSize";
import JournalCard from "../../components/card/JournalCard";
import { currentLang } from "../../configs/lang";
import JournalsService from "../../services/service/journals";

export default function Journals() {
  let [journals, setJournals] = useState([]);
  let [meta, setMeta] = useState({});

  let lang = currentLang;

  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(journalSize);
  const [keyword, setKeyword] = useState("");
  // eslint-disable-next-line
  const [sort, setSort] = useState("desc");

  // eslint-disable-next-line
  const [date, setDate] = useState({
    startDate: new Date(
      new Date().getFullYear() - 4,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getJournals();

    // eslint-disable-next-line
  }, [keyword, date, sort, page, pageSize]);

  const getJournals = async () => {
    const startDateObj = new Date(date.endDate);
    startDateObj.setHours(0, 0, 0, 0);
    const endDateObj = new Date(startDateObj.getTime() + 86399999);
    const endDate = endDateObj.toISOString();

    try {
      let [res, meta] = await JournalsService.getJournals({
        ...(keyword && { keyword: keyword }),
        startDate: date.startDate,
        endDate: endDate,
        sort: sort,
        page: page,
        pageSize: pageSize,
      });

      setJournals(res);
      setMeta(meta);
    } catch {
      console.error();
    }
  };

  const handleKeywordChange = (newKeyword) => {
    setKeyword(newKeyword);
  };

  // const handleDateChange = (newValue) => {
  //   setDate(newValue);
  // };

  const bannerText = [
    { name: "service", href: "/service" },
    { name: "Journals", href: "/service/journals" },
  ];

  return (
    <div id="journals-all" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={"วารสาร / จุลสาร"}
        text_en={"Documents"}
        // banner={newsBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* ข่าวสาร */}
          <div id="news-section" className="pt-[54px] pb-[48px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th" ? "รายการวารสารที่ให้บริการ" : "News"}
            </h1>

            <div className="my-[20px] flex flex-col lg:flex-row items-start justify-between gap-[53px]">
              <div className="relative w-full">
                <input
                  defaultValue={keyword}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                  placeholder={
                    lang === "th" ? "ค้นหาจาก Keyword" : "Finding by Keyword"
                  }
                  className="w-full font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-[25px] cursor-pointer absolute text-darkGray top-[12px] right-[15px] pointer-events-none"
                />
              </div>
              <div className="flex items-center gap-[50px]">
                <h1 className="text-[20px] whitespace-nowrap">
                  {lang === "th" ? "เรียงลำดับ" : "Sort By"}
                </h1>
                <select
                  name="ordering"
                  className="w-fit font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                  value={sort}
                  onChange={(event) => setSort(event.target.value)}
                >
                  <option value="desc">
                    {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                  </option>
                  <option value="asc">
                    {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                  </option>
                </select>
              </div>
            </div>

            <p className="text-[16px] xl:text-[18px] text-left text-brown">
              หมายเหตุ : วารสารบริการสำหรับสมาชิกสมาคมพยาบาลฯ เท่านั้น
            </p>

            {journals && (
              <div className="grid grid-cols-2 gap-[18px] mt-[38px]">
                {journals.map((item, i) => (
                  <JournalCard data={item} key={i} lang={lang} />
                ))}
              </div>
            )}

            {journals && journals.length <= 0 && (
              <p className="text-[23px] text-center mt-[40px]">
                {lang === "th"
                  ? "ไม่พบข้อมูลที่ค้นหา..."
                  : "Not found any news ..."}
              </p>
            )}

            {
              <Pagination
                length={meta?.pagination?.pageCount ?? ""}
                selected={page}
                function={(i) => setPage(i)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
