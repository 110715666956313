import React, { useEffect, useState } from "react";
import AboutUsService from "../../services/about-us/about-us";
// import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import { currentLang } from "../../configs/lang";

export default function Vision() {
  let lang = currentLang;
  let [visionData, setVisionData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await AboutUsService.getVisionData();
      setVisionData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "Vision&Mission", href: "/abouts/mission" },
  ];

  return (
    <>
      {visionData && (
        <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
          <TextBanner
            text_th={"วิสัยทัศน์ และพันธกิจ"}
            text_en={"Vision&Mission"}
            // banner={serviceBanner.banner}
            bannerText={bannerText}
            lang={lang}
          />

          <div className="section">
            <div className="page-container">
              <h1 className="text-[28px] xl:text-[32px] text-left mt-[75px]">
                {lang === "th"
                  ? visionData.content[0]?.title_TH
                  : visionData.content[0]?.title_EN}
              </h1>
              {visionData.content && (
                <div
                  className="content-html text-left my-[30px] font-normal"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "th"
                        ? visionData.content[0]?.body_TH
                        : visionData.content[0]?.body_EN,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
