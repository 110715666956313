import http from "../http-common";
import qs from "qs";
import JournalsModel from "../../models/service/journals";

// eslint-disable-next-line
export default new (class JournalsService {
  async getJournals({
    keyword,
    startDate = null,
    endDate = null,
    sort = "desc",
    page = 1,
    pageSize = 5,
    category = "journal",
    subcategory = null,
  }) {
    const filters = {
      $or: [
        {
          journal: {
            name_TH: {
              $contains: keyword,
            },
          },
        },
        {
          journal: {
            name_EN: {
              $contains: keyword,
            },
          },
        },
      ],
      $and: [],
    };

    // Add date filters only if startDate or endDate is provided
    if (startDate) {
      filters.$and.push({
        createdAt: {
          $gt: new Date(startDate).toISOString(),
        },
      });
    }

    if (endDate) {
      filters.$and.push({
        createdAt: {
          $lt: new Date(endDate).toISOString(),
        },
      });
    }

    if (category) {
      filters.$and.push({
        category: {
          $eq: category,
        },
      });
    }

    if (subcategory) {
      filters.$and.push({
        category: {
          $eq: category,
        },
      });
    }

    // Build query with filters
    const query = qs.stringify(
      {
        sort: [`createdAt:${sort}`],
        filters: filters,
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return await http
      .get("/services?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((items) => JournalsModel.get(items))
        );

        const meta = response.data.meta;

        return [data, meta];
      })
      .catch((error) => {
        console.error(error);
      });
  }

    async getJournalsBySlug(slug) {
      return await http
        .get(`/services?filters[slug][$eq]=${slug}`)
        .then(async (response) => {
          const id = response.data.data[0].id;

          const news = await http
            .get(`/services/${id}?populate=deep`)
            .then(async (response) => {
              const data = await JournalsModel.get(response.data.data);
              return data;
            });

          return news;
        })
        .catch((error) => {
          console.error(error);
        });
    }
})();
