import React, { useEffect, useState } from "react";
import PageBanner from "../../components/banner/PageBanner";
import BannerService from "../../services/components/banner";
import { useHistory } from "react-router-dom";
import CustomButton from "../../components/button/CustomButton";
import { currentLang } from "../../configs/lang";

export default function Service() {
  //   let [serviceData, setServiceData] = useState([]);
  let [serviceBanner, setServiceBanner] = useState([]);
  const [inputValue, setInputValue] = useState("");

  let lang = currentLang;
  const history = useHistory();

  // const searchMember = (input) => {
  //   const name = encodeURIComponent(input);
  //   history.push(`/service/member_check?name=${name}`);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    getServiceBanner();
  }, []);

  const getServiceBanner = async () => {
    try {
      let res = await BannerService.getBanner("service");
      setServiceBanner(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [{ name: "service", href: "/service" }];

  return (
    <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <PageBanner
        text_th={"บริการสมาชิก"}
        text_en={"Service"}
        banner={serviceBanner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* บริการ */}
          <div className="mt-[54px] mb-[300px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th" ? "บริการสมาชิก" : "Service"}
            </h1>

            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-[50px]">
              <div className="mt-[27px] col-span-2 grid grid-cols-1 gap-[20px] h-fit">
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/service/service_request`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  แจ้งรับสวัสดิการ
                </div>
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/service/journals`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  วารสาร/จุลสารวิชาการ
                </div>
                {/* <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/service/apply_scholarship`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  สมัครทุนการศึกษา
                </div>
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/service/apply_trainning`);
                  }}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  สมัครประชุม/สัมมนา
                </div> */}
              </div>

              <div className="col-span-1 items-start">
                {/* <div className="items-start w-full flex flex-col gap-[12px]">
                  <div>
                    <p className="text-[22px] xl:text-[24px] text-left">
                      ตรวจสอบสมาชิกภาพ
                    </p>
                    <h1 className="text-[28px] xl:text-[32px] text-left">
                      หรือ <span className="text-gold">เข้าสู่ระบบ</span>
                    </h1>
                  </div>
                  <div className="justify-start py-[17px] px-[24px] flex flex-col gap-[10px] rounded-[12px] border-[1px] border-lightGray2">
                    <p className="text-[16px] xl:text-[18px] text-left">
                      หมายเลขสมาชิก หรือ เลขบัตรประชาชน
                    </p>
                    <input
                      className="w-full placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]"
                      placeholder="กรุณากรอก ชื่อ-นามสกุล"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <CustomButton
                      hasIcon
                      text={"ตรวจสอบ"}
                      onClick={() => {
                        if (inputValue !== "") searchMember(inputValue);
                      }}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        window.scroll(0, 0);
                        history.push(
                          `/service/service_request/member-registration`
                        );
                      }}
                      className="text-gold text-[20px] xl:text-[22px]"
                    >{`หรือ สมัครสมาชิก >>`}</p>
                  </div>
                </div> */}

                {/* Hide */}
                <div className="items-start w-full flex flex-col gap-[12px]">
                  <div>
                    <p className="text-[22px] xl:text-[26px] text-left">
                      ตรวจสอบสมาชิกภาพ
                    </p>
                    {/* <h1 className="text-[28px] xl:text-[32px] text-left">
                      หรือ <span className="text-gold">เข้าสู่ระบบ</span>
                    </h1> */}
                    <h1 className="text-[28px] xl:text-[32px] text-left text-darkGray">
                      เริ่มใช้งานได้ เร็วๆ นี้
                    </h1>
                    {/* <p className="text-[12px] xl:text-[14px] text-left">
                      กรุณากรอก ชื่อของท่าน ตามด้วย - และกรอกนามสกุล ตัวอย่าง
                      วันดี-ณ อยุธยา, พิศมัย-สมสกุล เป็นต้น
                    </p> */}
                  </div>
                  <div className="pointer-events-none py-[17px] px-[24px] flex flex-col gap-[10px] rounded-[12px] border-[1px] border-lightGray2 bg-gray-200 text-[gray-500] opacity-70">
                    <input
                      className="w-full placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]"
                      placeholder="กรุณากรอก ชื่อ-นามสกุล"
                      disabled
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <CustomButton
                      hasIcon
                      text={"ตรวจสอบ"}
                      onClick={() => {
                        // if (inputValue !== "") searchMember(inputValue);
                      }}
                    />
                  </div>
                  <div>
                    <p
                      onClick={() => {
                        window.scroll(0, 0);
                        history.push(
                          `/service/service_request/member-registration`
                        );
                      }}
                      className="text-gold text-[20px] xl:text-[22px] cursor-pointer"
                    >{`หรือ สมัครสมาชิก >>`}</p>
                  </div>
                </div>
                {/* Hide */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
