// eslint-disable-next-line
export default new (class TemplateModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.name = data.attributes.name || "";
    model.detail = data.attributes.detail || [];
    model.link = data.attributes.link || "";
    model.external_link = data.attributes.external_link || []

    return model;
  }
})();
