import React from "react";
import chevronRed from "../assets/images/global/chev_red.svg";
import chevronGray from "../assets/images/global/chev-gray.svg";

export default function Pagination(props) {
  const { selected, length } = props;

  const showPages = () => {
    const pages = [];

    if (length <= 7) {
      for (let i = 1; i <= length; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (selected > 4) pages.push("...");

      for (
        let i = Math.max(2, selected - 1);
        i <= Math.min(length - 1, selected + 1);
        i++
      ) {
        pages.push(i);
      }

      if (selected < length - 3) pages.push("...");
      pages.push(length);
    }

    return pages;
  };

  return (
    <div className="pagination mt-[100px] section gap-[12px]">
      <div
        onClick={() => {
          if (selected - 1 > 0) props.function(selected - 1);
        }}
      >
        <img className="w-[18px] cursor-pointer" src={chevronGray} alt="" />
      </div>
      {showPages().map((item, i) => (
        <span key={i} className="text-[24px] font-medium cursor-pointer">
          {typeof item === "number" ? (
            <span
              className={item === selected ? "text-gold underline" : "text-red"}
              onClick={() => {
                props.function(item);
              }}
            >
              {item}
              {i !== showPages().length - 1 && ", "}
            </span>
          ) : (
            <span className="text-red">...</span>
          )}
        </span>
      ))}
      <div
        onClick={() => {
          if (selected + 1 <= length) props.function(selected + 1);
        }}
      >
        <img className="w-[18px] cursor-pointer" src={chevronRed} alt="" />
      </div>
    </div>
  );
}
