import React from "react";
import chevronsSmall from "../../assets/images/global/chev_blue_small.svg";
import timeFunction from "../../functions/getTime";

export default function DateCard(props) {
  return (
    <>
      {props.type === "news" ? (
        <div
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_WEB_URL}/newsAndActivity/news/${props.data.slug}`,
              "_self"
            );
          }}
          className={
            "flex flex-col dark-overlay-round rounded-[12px] flex pt-[13px] pb-[9px] px-[20px] gap-[20px] !bg-cover " +
            `${props.hasBg ? null : `bg-white border-[1px] border-lightGray2`}`
          }
          style={{
            background:
              props.data &&
              props.data.image[0] &&
              `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url}), #AEAEAE`,
          }}
        >
          <div className={`z-[2] ${props.hasBg && "text-white"}`}>
            {props.data && props.data.title && (
              <p className="cursor-pointer text-[18px] xl:text-[20px] font-medium text-left line-clamp-3">
                {props.lang === "th"
                  ? props.data.title.detail_TH
                  : props.data.title.detail_EN}
              </p>
            )}
          </div>

          <p
            className={`z-[2] text-[18px] xl:text-[20px] text-left text-normal ${
              props.hasBg && "text-white"
            }`}
          >
            {props.data &&
              timeFunction.getPrideData(props.data.publishedAt, props.lang)}
          </p>
        </div>
      ) : (
        <div
          id="datecard"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_WEB_URL}/newsAndActivity/news/${props.data.slug}`,
              "_self"
            );
          }}
          className={
            "dark-overlay rounded-[12px] flex pt-[13px] pb-[9px] px-[20px] gap-[20px] !bg-cover " +
            `${props.hasBg ? null : `bg-white border-[1px] border-lightGray2`}`
          }
          style={{
            background:
              props.data &&
              props.data.image[0] &&
              `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url}), #D9D9D9`,
          }}
        >
          <div
            className={`${
              props.hasBg && "z-[2] text-white flex flex-col items-center"
            }`}
          >
            <h1 className="text-[28px] xl:text-[36px] font-medium leading-[43px]">
              {
                timeFunction.getActivityTime(
                  props.lang,
                  props.data.publishedAt
                )[0]
              }
            </h1>
            <h1 className="text-[20px] xl:text-[24px] font-medium leading-[28px] mt-[-8px]">
              {
                timeFunction.getActivityTime(
                  props.lang,
                  props.data.publishedAt
                )[1]
              }
            </h1>
            <p className="text-[8px] xl:text-[9px] font-medium leading-[14px]">
              {
                timeFunction.getActivityTime(
                  props.lang,
                  props.data.publishedAt
                )[3]
              }
            </p>
          </div>
          <div
            id="datecard-info"
            className={`z-[2] ${props.hasBg && "text-white"}`}
          >
            {props.data && props.data.title && (
              <p className="text-[16px] xl:text-[18px] font-medium text-left line-clamp-3">
                {props.lang === "th"
                  ? props.data.title.detail_TH
                  : props.data.title.detail_EN}
              </p>
            )}
            <div className="flex justify-end mt-[8px]">
              <img
                className="w-[18px] cursor-pointer"
                src={chevronsSmall}
                alt=""
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
