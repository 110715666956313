import React, { useEffect, useState } from "react";
import NavbarService from "../services/components/navbar.js";
import NavbarDropdown from "./NavbarDropdown.js";
import chevronDown from "../assets/images/global/chev_down.svg";
import { Link } from "react-router-dom";
// import user from "../assets/images/navbar/user-gold.svg";
// import globe from "../assets/images/navbar/red-globe.svg";
import logo from "../assets/images/global/logo.png";
// import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { currentLang } from "../configs/lang.js";

export default function Navbar(props) {
  let [navbarData, setNavbarData] = useState([]);

  let lang = currentLang

  const location = useLocation();

  useEffect(() => {
    closeDropdown();
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    getNavbarData();
  }, []);

  const getNavbarData = async () => {
    try {
      let res = await NavbarService.getNavbar();
      setNavbarData(res);
    } catch {
      console.error();
    }
  };

  const openDropdown = (i) => {
    const temp = navbarData.slice();

    const result = temp.map((e) => {
      if (e.id === i + 1) {
        return { ...e, isOpen: !temp[i].isOpen };
      } else {
        return e;
      }
    });

    setNavbarData(result);
  };

  const closeDropdown = () => {
    const temp = navbarData.slice();

    const result = temp.map((e) => {
      return { ...e, isOpen: false };
    });

    setNavbarData(result);
  };

  // function switchLang() {
  //   const currentPath = window.location.pathname;
  //   const currentLanguage = currentPath.split("/")[1]; // get the current language code from the path
  //   let newLanguage;
  //   if (currentLanguage === "en") {
  //     newLanguage = "th";
  //   } else if (currentLanguage === "th") {
  //     newLanguage = "en";
  //   }
  //   let newPath;
  //   if (currentPath.endsWith(`/${currentLanguage}`)) {
  //     // if the current path ends with "/en" or "/th", replace it with the new language code
  //     newPath = currentPath.replace(`/${currentLanguage}`, `/${newLanguage}`);
  //   } else {
  //     // otherwise, replace the language code in the middle of the path
  //     newPath = currentPath.replace(`/${currentLanguage}/`, `/${newLanguage}/`);
  //   }
  //   window.location.href = window.location.origin + newPath;
  // }

  return (
    <div id="navbar" className="fixed lg:relative z-[99] w-full">
      <div className="bg-white section">
        <div className="navbar-container py-[20px] flex justify-between items-center !z-[1]">
          <Link to={`/`}>
            <div className="flex sm:divide-x divide-gold gap-[5px] sm:gap-[19px] py-[2px] items-center">
              <div>
                <img className="w-[55px] sm:w-[80px]" src={logo} alt="" />
              </div>

              <div className="flex flex-col mt-[15px]">
                {
                  <p className="capitalize font-bold text-[14px] sm:text-[18px] xl:text-[22px] text-brown text-left sm:leading-7 pl-[5px] sm:pl-[19px] whitespace-pre-line">
                    สมาคมพยาบาลแห่งประเทศไทยฯ
                  </p>
                }

                {
                  <p className="capitalize text-[10px] sm:text-[16px] xl:text-[20px] text-brown text-left sm:leading-7 pl-[5px] sm:pl-[19px] whitespace-pre-line">
                    สำนักงานสาขาภาคตะวันออก
                  </p>
                }
              </div>
            </div>
          </Link>

          <div className="hidden lg:flex items-center justify-center gap-[24px] ">
            {/* <div className="relative">
              <input
                className="min-w-[265px] placeholder-lightGray2 text-[16px] rounded-[8px] border-[1px] border-lightGray2 py-[14px] px-[18px]"
                placeholder="ค้นหา..."
              />
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className={
                  "absolute top-[16px] right-[18px] pointer-events-none h-[18px] w-[18px] cursor-pointer text-darkGray"
                }
              />
            </div> */}

            {/* <div
              className="section gap-[9px] cursor-pointer"
              onClick={() => {
                switchLang();
              }}
            >
              <img className="w-[36px]" src={globe} alt="" />
              <p className="text-[20px] xl:text-[24px] text-red">
                {lang === "th" ? "TH" : "EN"}
              </p>
            </div> */}

            {/* <div>
              <img className="w-[34px] cursor-pointer" src={user} alt="" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="hidden lg:flex items-center justify-center bg-white border-t-[7px] border-t-red border-b-[2px] border-b-gold">
        <div className="navbar-container flex justify-around h-full">
          {navbarData &&
            navbarData.map((item, i) => (
              <div
                key={i}
                className={`hover:underline cursor-pointer flex gap-[20px] py-[13px] items-center relative w-full justify-center `}
                onClick={() => openDropdown(i)}
              >
                <Link
                  to={
                    item.link && item.link === "-" ? "" : `${item.link}`
                  }
                  className={
                    item.link && item.link === "-"
                      ? "pointer-events-none"
                      : undefined
                  }
                >
                  <h1
                    className={`capitalize text-[18px] xl:text-[20px] text-brown `}
                  >
                    {item[`name_${lang}`]}
                  </h1>
                </Link>

                {item.subMenu && item.subMenu.length > 1 && (
                  <img
                    className={`w-[15px] cursor-pointer ${
                      item.isOpen ? "rotate-180" : ""
                    }`}
                    src={chevronDown}
                    alt=""
                    onClick={() => openDropdown(i)}
                  />
                )}

                {item.subMenu && item.subMenu.length > 1 && (
                  <NavbarDropdown
                    isOpen={!item.isOpen}
                    subMenu={item.subMenu}
                    closeDropdown={closeDropdown}
                    lang={lang}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
