// eslint-disable-next-line
export default new (class JournalsModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.slug = data.attributes.slug || "";

    model.title = {
      detail_EN: data.attributes.journal.name_EN || "",
      detail_TH: data.attributes.journal.name_TH || "",
    };

    model.description = {
      detail_EN: data.attributes.journal.detail?.detail_EN || "",
      detail_TH: data.attributes.journal.detail?.detail_TH || "",
    };

    model.tag = data.attributes.journal.tag?.map((tag) => tag.tag) || [];
    model.link = data.attributes.journal.link;

    if (data.attributes.journal.cover_image) {
      model.image = data.attributes.journal.cover_image.image.data;
    } else {
      model.image = "";
    }

    model.content = data.attributes.journal || {};
    model.createdAt = data.attributes.createdAt || "";
    model.publishedAt = data.attributes.publishedAt || "";

    return model;
  }
})();
