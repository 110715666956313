import React from "react";
import chevBrown from "../../assets/images/global/chev-brown.svg";

export default function TextBanner(props) {
  return (
    <div className="section bg-gold py-[20px]">
      <div className="page-container items-start flex flex-col justify-end">
        {props.bannerText && (
          <p className="text-[16px] xl:text-[18px] font-medium text-left text-brown cursor-pointer flex flex-wrap gap-[12px]">
            <a href={`/`}>{props.lang === "th" ? "หน้าหลัก" : "Mainpage"}</a>
            {props.bannerText.map((item, i) => (
              <span
                key={i}
                className="flex items-center gap-[13px]"
                onClick={() => {
                  if (
                    item.href &&
                    window.location.pathname !== `${item.href}`
                  ) {
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}${item.href}`,
                      "_self"
                    );
                  }
                }}
              >
                {" "}
                <img src={chevBrown} alt="" />{" "}
                <span className="capitalize max-w-[400px] line-clamp-1">{item.name}</span>
              </span>
            ))}
          </p>
        )}
        {!props.noTitle && (
          <h1 className="capitalize text-[28px] xl:text-[36px] font-medium text-left text-brown mt-[10px]">
            {props.lang === "th" ? props.text_th : props.text_en}
          </h1>
        )}
      </div>
    </div>
  );
}
