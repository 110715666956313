import http from "./../http-common";

// eslint-disable-next-line
export default new (class AboutUsService {
  async getPresidentData() {
    return await http
      .get(
        "/about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&populate[4]=content.image&filters[slug][$eq]=president_of_the_association"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getOrganizeData() {
    return await http
      .get(
        "about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&populate[4]=content.image&filters[slug][$eq]=organization_chart"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getVisionData() {
    return await http
      .get(
        "about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&filters[slug][$eq]=vision_and_mission"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getObjData() {
    return await http
      .get(
        "about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&filters[slug][$eq]=objectives"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getHistoryData() {
    return await http
      .get(
        "about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&filters[slug][$eq]=history"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getMemberData() {
    return await http
      .get(
        "about-uses?populate[0]=content&populate[1]=tag&populate[2]=content.slug&populate[3]=content.detail&filters[slug][$eq]=member_welfare_regulations"
      )
      .then((response) => {
        const data = response.data.data[0].attributes;

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getContactData() {
    return await http
      .get("about-contact-uses?populate=deep")
      .then((response) => {
        const data = response.data.data;

        return data.reduce((acc, item) => {
          const { section, content, createdAt } = item.attributes;
          const title = item.attributes.title ? item.attributes.title : null;

          acc[section.toLowerCase()] = {
            createdAt,
            title,
            content
          };

          return acc;
        }, {});
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
