import http from "./../http-common";
import BannerModel from "../../models/components/banner";

// Navbar APIs
// eslint-disable-next-line
export default new (class BannerService {
  // Get all members d
  async getBanner(page) {
    return await http
      .get(
        `/banners?populate[0]=Banner&populate[1]=Banner.image&filters[page][$eq]=${page}`
      )
      .then(async (response) => {
        const data = await BannerModel.get(response.data.data[0]);

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
