import http from "./../http-common";
import qs from "qs";
import NewsModel from "../../models/news-activity/news";
import ActivityNewsModel from "../../models/news-activity/activity";

// eslint-disable-next-line
export default new (class NewsService {
  // Get all
  async getNews({
    keyword,
    startDate,
    endDate,
    sort = "desc",
    page = 1,
    pageSize = 5,
    category = null,
    subcategory = null,
    isSubArray = false,
  }) {
    const filters = {
      $or: [
        {
          title: {
            detail_TH: {
              $contains: keyword,
            },
          },
        },
        {
          title: {
            detail_EN: {
              $contains: keyword,
            },
          },
        },
        {
          tag: {
            tag: {
              $eq: keyword,
            },
          },
        },
      ],
      $and: [],
    };

    // Add date filters only if startDate or endDate is provided
    if (startDate) {
      filters.$and.push({
        publishedAt: {
          $gt: new Date(startDate).toISOString(),
        },
      });
    }

    if (endDate) {
      filters.$and.push({
        publishedAt: {
          $lt: new Date(endDate).toISOString(),
        },
      });
    }

    if (category) {
      filters.$and.push({
        category: {
          $eq: category,
        },
      });
    }

    if (!isSubArray) {
      if (subcategory) {
        filters.$and.push({
          subcategory: {
            $eq: subcategory,
          },
        });
      }
    } else {
      if (subcategory && Array.isArray(subcategory) && subcategory.length > 0) {
        filters.$and.push({
          subcategory: {
            $in: subcategory,
          },
        });
      }
    }

    // Build query with filters
    const query = qs.stringify(
      {
        sort: [`publishedAt:${sort}`],
        filters: filters,
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        const meta = response.data.meta;

        return [data, meta];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getMainpageNews({
    sort = "desc",
    page = 1,
    pageSize = 3,
    category = null,
    subcategory = null,
  }) {
    const query = qs.stringify(
      {
        sort: [`publishedAt:${sort}`],
        ...(category && { filters: { category: { $eq: category } } }),
        ...(subcategory && { filters: { subcategory: { $eq: subcategory } } }),
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        return [data];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Get by name
  async getNewsBySlug(slug) {
    return await http
      .get(`/news?filters[Slug][$eq]=${slug}`)
      .then(async (response) => {
        const id = response.data.data[0].id;

        const news = await http
          .get(`/news/${id}?populate=deep`)
          .then(async (response) => {
            const data = await NewsModel.get(response.data.data);
            return data;
          });

        return news;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getNewsByTag({ sort = "desc", tag = "" }) {
    const query = qs.stringify(
      {
        filters: {
          $and: [
            {
              tag: {
                tag: {
                  $eq: tag,
                },
              },
            },
          ],
        },
        sort: [`publishedAt:${sort}`],
        populate: "deep",
      },
      {
        encodeValuesOnly: true,
      }
    );
    return await http
      .get("/news?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((news) => NewsModel.get(news))
        );

        return [data];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getActivity({
    keyword,
    startDate = null,
    endDate = null,
    sort = "desc",
    page = 1,
    pageSize = 5,
    category = null,
    subcategory = null,
  }) {
    const filters = {
      $or: [
        {
          activity: {
            event_TH: {
              $contains: keyword,
            },
          },
        },
        {
          activity: {
            event_EN: {
              $contains: keyword,
            },
          },
        },
        {
          activity: {
            tag: {
              tag: {
                $eq: keyword,
              },
            },
          },
        },
      ],
      $and: [],
    };

    // Add date filters only if startDate or endDate is provided
    if (startDate) {
      filters.$and.push({
        createdAt: {
          $gt: new Date(startDate).toISOString(),
        },
      });
    }

    if (endDate) {
      filters.$and.push({
        createdAt: {
          $lt: new Date(endDate).toISOString(),
        },
      });
    }

    if (category) {
      filters.$and.push({
        activity: {
          category: {
            $eq: category,
          },
        },
      });
    }

    if (subcategory) {
      filters.$and.push({
        activity: {
          subcategory: {
            $eq: subcategory,
          },
        },
      });
    }

    // Build query with filters
    const query = qs.stringify(
      {
        sort: [`createdAt:${sort}`],
        filters: filters,
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return await http
      .get("/activities?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((items) => ActivityNewsModel.get(items))
        );

        const meta = response.data.meta;

        return [data, meta];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getActivityBySlug(slug) {
    return await http
      .get(`/activities?filters[slug][$eq]=${slug}`)
      .then(async (response) => {
        const id = response.data.data[0].id;

        const news = await http
          .get(`/activities/${id}?populate=deep`)
          .then(async (response) => {
            const data = await ActivityNewsModel.get(response.data.data);
            return data;
          });

        return news;
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
