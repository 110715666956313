import http from "./../http-common";
import TemplateModel from "../../models/components/template";

// Navbar APIs
// eslint-disable-next-line
export default new (class TemplateService {
  // Get all members
  async getTemplate() {
    return await http
      .get("/template-heads?populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => TemplateModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getAgency() {
    return await http
      .get("/template-heads?filters[isAgency][$eq]=true&populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((footer) => TemplateModel.get(footer))
        );

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
