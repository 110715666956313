import React from "react";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

export default function NavbarDropdown(props) {
  return (
    <div className="navbar-dropdown bg-white absolute">
      <SlideDown className={"my-dropdown-slidedown"} closed={props.isOpen}>
        {props.subMenu &&
          props.subMenu.map((item, i) => (
            <div
              onClick={() => {
                if (item.href)
                  window.open(
                    `${process.env.REACT_APP_WEB_URL}${item.href}`,
                    "_self"
                  );
                props.closeDropdown();
              }}
              key={i}
            >
              <h1
                key={i}
                className="hover:underline capitalize py-[16px] px-[40px] border-[0.5px] border-lightGray3 text-[18px] xl:text-[20px] font-normal drop-shadow-2xl whitespace-nowrap cursor-pointer"
              >
                {props.lang === "th" ? item.label_TH : item.label_EN}
              </h1>
            </div>
          ))}
      </SlideDown>
    </div>
  );
}
