import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageBanner from "../../components/banner/PageBanner";
import AllButton from "../../components/button/AllButton";
import AwardCard from "../../components/card/AwardCard";
import HorizontalCard from "../../components/card/HorizontalCard";
import VerticleCard from "../../components/card/VerticleCard";
import BannerService from "../../services/components/banner";
import PrideService from "../../services/pride/pride";
import NewsService from "../../services/news-activity/news";
import ImageCard from "../../components/card/ImageCard";
import { currentLang } from "../../configs/lang";

export default function OurPrides() {
  let lang = currentLang;
  let [banner, setBanner] = useState([]);
  let [nurse, setNurse] = useState([]);
  let [social, setSocial] = useState([]);
  let [activity, setActivity] = useState([]);
  let [gallery, setGallery] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getBanner();
    getNurse();
    getSocialActivity();
    getActivity();
    getGallery();
  }, []);

  const bannerText = [{ name: "Our Prides", href: "/honor" }];

  const getBanner = async () => {
    try {
      let res = await BannerService.getBanner('pride');
      setBanner(res);
    } catch {
      console.error();
    }
  };

  const getNurse = async () => {
    try {
      let res = await PrideService.getNurse(4);
      setNurse(res);
    } catch {
      console.error();
    }
  };

  const getSocialActivity = async () => {
    try {
      let res = await PrideService.getSocialActivity(2);
      setSocial(res);
    } catch {
      console.error();
    }
  };

  const getActivity = async () => {
    try {
      let [res] = await NewsService.getActivity({
        page: 1,
        pageSize: 2,
        category: "social_service",
      });

      setActivity(res);
    } catch {
      console.error();
    }
  };

  const getGallery = async () => {
    try {
      let [res] = await NewsService.getActivity({
        page: 1,
        pageSize: 3,
        category: "activity_gallery",
      });

      setGallery(res);
    } catch {
      console.error();
    }
  };

  const prideButton = [
    {
      name: "รางวัล/ผลงานพยาบาล",
      href: "/awards",
    },
    {
      name: "กิจกรรมบริการสังคม",
      href: "/social_activities",
    },
    {
      name: "กิจกรรมของสมาคมฯ",
      href: "/activities",
    },
  ];

  return (
    <div
      id="ourprides"
      className="pt-[100px] sm:pt-[135px] lg:pt-[0px] pb-[100px]"
    >
      <PageBanner
        text_th={"ผลงาน & ความภาคภูมิใจ"}
        text_en={"Our Prides"}
        banner={banner.banner}
        bannerText={bannerText}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          <div className="mt-[49px]">
            <h1 className="text-[28px] xl:text-[32px] text-left">
              {lang === "th" ? "บริการสมาชิก" : "Service"}
            </h1>

            <div className="mt-[27px] grid grid-cols-1 md:grid-cols-3 gap-[20px] h-fit">
              {prideButton.map((item, i) => (
                <div
                  onClick={() => {
                    window.scroll(0, 0);
                    history.push(`/honor${item.href}`);
                  }}
                  key={i}
                  className="cursor-pointer shadow-md border-[1px] border-gold rounded-[8px] section py-[32px] text-[28px] xl:text-[32px] !font-normal"
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[49px]">
            <div className="flex items-center justify-between">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "รางวัล/ผลงานพยาบาล"
                  : "Award/Nursing Performance"}
              </h1>

              <Link className="flex justify-end" to={`/honor/awards`}>
                <AllButton lang={lang} />
              </Link>
            </div>

            {nurse && (
              <div className="mt-[32px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[20px]">
                {nurse.map((item, i) => (
                  <AwardCard
                    key={i}
                    data={item.attributes}
                    lang={lang}
                    image={
                      item.attributes.pride?.cover_image[0]?.image?.data[0]
                        ?.attributes
                    }
                  />
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-col lg:grid lg:grid-cols-3 gap-[32px] mt-[49px]">
            <div className="col-span-1">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "กิจกรรมบริการสังคม"
                  : "Social Service Activities"}
              </h1>

              {social && (
                <div
                  id="mainpage-activity-section"
                  className="grid grid-cols-1 gap-[18px] mt-[34px]"
                >
                  {social.map((item, i) => (
                    <HorizontalCard
                      size="small"
                      data={item.attributes}
                      key={i}
                      page="newsAndActivity/news"
                      lang={lang}
                      type="pride"
                    />
                  ))}
                </div>
              )}
              <Link
                className="flex justify-end mt-[20px]"
                to={`/honor/social_activities`}
              >
                <AllButton lang={lang} />
              </Link>
            </div>
            <div className="col-span-2">
              <h1 className="text-[28px] xl:text-[32px] text-left font-normal">
                {lang === "th"
                  ? "กิจกรรมของสมาคมฯ"
                  : "Activities of the Association"}
              </h1>

              {activity && (
                <div
                  id="mainpage-activity-section"
                  className="grid grid-cols-2 gap-[18px] mt-[34px]"
                >
                  {activity.map((item, i) => (
                    <VerticleCard
                      data={item}
                      key={i}
                      lang={lang}
                      type="pride"
                      image={item.image[0]?.attributes}
                    />
                  ))}
                </div>
              )}

              <Link
                className="flex justify-end mt-[20px]"
                to={`/honor/activities`}
              >
                <AllButton lang={lang} />
              </Link>
            </div>
          </div>

          <div className="mt-[45px] pb-[50px]">
            <h1 className="text-[28px] xl:text-[32px] text-left capitalize">
              ประมวลภาพกิจกรรม
            </h1>

            {gallery && (
              <div
                id="image-activity"
                className="grid grid-cols-3 lg:flex-row gap-[30px] mt-[68px]"
              >
                {gallery.map((item, i) => (
                  <ImageCard
                    data={item}
                    key={i}
                    lang={lang}
                    type="pride"
                    image={item.image[0]?.attributes}
                    subImage={item.subImage}
                  />
                ))}
              </div>
            )}

            <Link
              className="flex justify-end mt-[40px]"
              to={`/honor/activities`}
            >
              <AllButton
                lang={lang}
                customText={{ TH: "แฟ้มภาพทั้งหมด", EN: "See all galleries" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
