import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLine } from "@fortawesome/free-brands-svg-icons";
import TextBanner from "../components/banner/TextBanner";
import NewsService from "../services/news-activity/news";
import WelfareService from "../services/service/welfare";
import PrideService from "../services/pride/pride";
import JournalsService from "../services/service/journals";
import timeFunction from "../functions/getTime";
import { currentLang } from "../configs/lang";

export default function ContentDetail(props) {
  let { slug } = useParams();
  let lang = currentLang;
  let [contentData, setContentData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getContentData();
    // eslint-disable-next-line
  }, []);

  const shareOnFacebook = () => {
    const url = window.location.href; // get the current URL
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`; // create the Facebook share URL with the current URL as a parameter
    window.open(facebookUrl, "_blank", "width=600,height=400"); // open the Facebook share dialog in a new tab
  };

  const shareOnLine = () => {
    const url = window.location.href; // get the current URL
    const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
      url
    )}`; // create the Line share URL with the current URL as a parameter
    window.open(lineUrl, "_blank"); // open the Line share dialog in a new tab
  };

  const getContentData = async () => {
    try {
      if (props.page === "news") {
        let res = await NewsService.getNewsBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(
            `${process.env.REACT_APP_WEB_URL}/newsAndActivity/news`,
            "_self"
          );
        }
      } else if (props.page === "welfare") {
        let res = await WelfareService.getWelfareBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(
            `${process.env.REACT_APP_WEB_URL}/service/service_request`,
            "_self"
          );
        }
      } else if (
        props.page === "honor-awards" ||
        props.page === "honor-social_activities"
      ) {
        let res = await PrideService.getPrideBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(`${process.env.REACT_APP_WEB_URL}/honor`, "_self");
        }
      } else if (props.page === "honor-activities") {
        let res = await NewsService.getActivityBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(`${process.env.REACT_APP_WEB_URL}/honor`, "_self");
        }
      } else if (props.page === "journals") {
        let res = await JournalsService.getJournalsBySlug(`${slug}`);

        if (res) {
          setContentData(res);
        } else {
          window.open(
            `${process.env.REACT_APP_WEB_URL}/service/journals`,
            "_self"
          );
        }
      }
      // else if (props.page === "activities") {
      //   let res = await ActivitiesService.getActivitiesBySlug(`${slug}`);

      //   if (res) {
      //     setContentData(res);
      //   } else {
      //     window.open(
      //       `${process.env.REACT_APP_WEB_URL}/newsAndActivity/activity`,
      //       "_self"
      //     );
      //   }
      // }
      // else if (props.page === "article") {
      //   let res = await ArticlesService.getArticlesBySlug(`${slug}`);

      //   if (res) {
      //     setContentData(res);
      //   } else {
      //     window.open(
      //       `${process.env.REACT_APP_WEB_URL}/article`,
      //       "_self"
      //     );
      //   }
      // }
    } catch {
      console.error();
    }
  };

  const isLinkPage = () => {
    if (
      props.page === "newsAndActivity/news" ||
      props.page === "honor/awards/news" ||
      props.page === "honor/social_activities/news" ||
      props.page === "honor/activities/news" ||
      props.page === "service/service_request" ||
      props.page === "service/journals"
    ) {
      return true;
    }
    return false;
  };

  const bannerText = () => {
    if (contentData.title && props.page === "news")
      return [
        { name: "News and Activity", href: "/newsAndActivity" },
        { name: "News", href: "/newsAndActivity/news" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    else if (contentData.title && props.page === "welfare")
      return [
        { name: "Service", href: "/service" },
        { name: "Acknowledge Welfare", href: "/service/service_request" },
      ];
    else if (contentData.title && props.page === "honor-awards")
      return [
        { name: "Our Prides", href: "/honor" },
        { name: "Awards & Workings", href: "/honor/awards" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    else if (contentData.title && props.page === "honor-social_activities")
      return [
        { name: "Our Prides", href: "/honor" },
        { name: "Social Service Activities", href: "/honor/social_activities" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    else if (contentData.title && props.page === "honor-activities")
      return [
        { name: "Our Prides", href: "/honor" },
        { name: "Association's Activity", href: "/honor/activities" },
        {
          name:
            lang === "th"
              ? contentData?.title?.detail_TH
              : contentData?.title?.detail_EN,
        },
      ];
    else if (contentData.title && props.page === "journals")
      return [
        { name: "Service", href: "/service" },
        { name: "Journals", href: "/service/journals" },
      ];
    return [];
  };

  return (
    contentData.content && (
      <div className="content-detail pt-[100px] sm:pt-[135px] lg:pt-[0px]">
        <TextBanner
          text_th={contentData?.title?.detail_TH}
          text_en={contentData?.title?.detail_EN}
          // banner={newsBanner.banner}
          noTitle={
            props.page === "honor-awards" ||
            props.page === "honor-social_activities" ||
            props.page === "honor-activities" ||
            props.page === "journals" ||
            props.page === "welfare" ||
            props.page === "news"
          }
          bannerText={bannerText()}
          lang={lang}
        />
        {contentData.content && (
          <div className="section">
            <div className="page-container">
              <div className="mt-[54px] mb-[46px]">
                {contentData.title && isLinkPage && (
                  <h1 className="text-[28px] xl:text-[32px] text-left capitalize mt-[25px] line-clamp-3">
                    {lang === "th"
                      ? contentData.title.detail_TH
                      : contentData.title.detail_EN}
                  </h1>
                )}

                <div className="w-[120px] border-[1px] border-black my-[22px]" />

                <div className="flex justify-start items-start gap-[18px]">
                  <div className="flex items-center gap-[12px]">
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-[24px] cursor-pointer"
                    />
                    <p className="text-[18px] mt-[3px]">
                      {contentData &&
                        timeFunction.getTime(
                          props.page === "activities"
                            ? contentData?.date?.startDate
                            : contentData?.createdAt,
                          lang
                        )}
                    </p>
                  </div>
                  <div className="flex items-center gap-[12px]">
                    <p className="text-[18px] mt-[3px]">
                      {contentData?.tag?.map((item, i) => (
                        <span key={i}>
                          <>
                            <span
                              className="cursor-pointer text-blue hover:underline"
                              onClick={() => {
                                const name = encodeURIComponent(item);
                                let query = "";

                                switch (props.page) {
                                  case "honor-activities":
                                    query = `/honor/activities?activityQuery=${name}`;
                                    break;
                                  case "honor-social_activities":
                                    query = `/honor/social_activities?query=${name}`;
                                    break;
                                  case "honor-awards":
                                    query = `/honor/awards?query=${name}`;
                                    break;
                                  case "news":
                                    const categoryQueryMap = {
                                      news: `/newsAndActivity/news?query=${encodeURIComponent(
                                        item.tag
                                      )}`,
                                      meetingseminar: `/newsAndActivity/trainning_news?query=${encodeURIComponent(
                                        item.tag
                                      )}`,
                                      scholarships: `/newsAndActivity/scholarship_news?query=${encodeURIComponent(
                                        item.tag
                                      )}`,
                                    };
                                    query =
                                      categoryQueryMap[contentData.category] ||
                                      "";
                                    break;
                                  default:
                                    return; // Exit if the page is not recognized
                                }

                                if (query) {
                                  history.push(query);
                                }
                              }}
                            >
                              {props.page === "honor-social_activities" ||
                              props.page === "honor-awards" ||
                              props.page === "honor-activities"
                                ? `#${item}`
                                : `#${item.tag}`}
                            </span>
                            {i + 1 < contentData?.tag?.length && ", "}
                          </>
                        </span>
                      ))}
                    </p>
                  </div>
                </div>

                {props.page === "journals" && contentData.content && (
                  <div className="section">
                    <div
                      className="!bg-cover !bg-center w-[520px] h-[600px] rounded-[8px] mt-[30px]"
                      style={{
                        background:
                          contentData.content?.cover_image &&
                          contentData.content?.cover_image?.image?.data[0] &&
                          `url(${process.env.REACT_APP_API_URL}${contentData.content.cover_image.image.data[0].attributes.url}), #D9D9D9`,
                      }}
                    />
                  </div>
                )}

                {contentData.content && isLinkPage && (
                  <div
                    className="content-html text-left my-[32px]"
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === "th"
                          ? props.page === "honor-social_activities" ||
                            props.page === "honor-activities" ||
                            props.page === "honor-awards" ||
                            props.page === "journals"
                            ? contentData.content?.detail?.detail_TH
                            : contentData.content[0]?.body_TH
                          : props.page === "honor-social_activities" ||
                            props.page === "honor-activities" ||
                            props.page === "honor-awards" ||
                            props.page === "journals"
                          ? contentData.content?.detail?.detail_EN
                          : contentData.content[0]?.body_EN,
                    }}
                  />
                )}

                {props.page === "journals" &&
                  contentData.content &&
                  contentData.content?.link[0] && (
                    <div className="section flex-col mb-[80px]">
                      <p
                        className="text-red text-[20px] cursor-pointer"
                        onClick={() => {
                          window.open(
                            contentData.content.link[0].href,
                            "_blank"
                          );
                        }}
                      >
                        อ่านวารสาร
                      </p>
                      <p className="text-lightGray2 text-[16px] text-normal">
                        [เปิด tab ใหม่ เป็น browser สำหรับอ่านไฟล์ PDF]
                      </p>
                    </div>
                  )}

                {props.page === "news" && (
                  <div className="flex justify-end items-center gap-[18px] whitespace-nowrap">
                    <div className="w-full border-[1px] border-black my-[22px]" />
                    <div className="flex gap-[0px] items-center">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                    </div>
                    <p
                      className="text-[18px] cursor-pointer hover:underline"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_WEB_URL}/newsAndActivity/news/`,
                          "_self"
                        )
                      }
                    >
                      {lang === "th" ? (
                        <span>
                          {props.page === "news"
                            ? "ข่าวสารทั้งหมด"
                            : props.page === "activites"
                            ? "กิจกรรมทั้งหมด"
                            : "บทความทั้งหมด"}
                        </span>
                      ) : (
                        <span>
                          {props.page === "news"
                            ? "All News"
                            : props.page === "activites"
                            ? "All Activites"
                            : "All Articles"}
                        </span>
                      )}
                    </p>
                  </div>
                )}

                {(props.page === "honor-awards" ||
                  props.page === "honor-activities" ||
                  props.page === "honor-social_activities") && (
                  <div className="flex justify-end items-center gap-[18px] whitespace-nowrap">
                    <div className="w-full border-[1px] border-black my-[22px]" />
                    <div className="flex gap-[0px] items-center">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                    </div>
                    <p
                      className="text-[18px] cursor-pointer hover:underline"
                      onClick={() => {
                        let link = "";

                        if (props.page === "honor-awards") {
                          link = "/honor/awards";
                        } else if (props.page === "honor-social_activities") {
                          link = "/honor/social_activities";
                        } else {
                          link = "/honor/activities";
                        }

                        window.open(
                          `${process.env.REACT_APP_WEB_URL}${link}`,
                          "_self"
                        );
                      }}
                    >
                      {lang === "th" ? (
                        <span>ข่าวสารทั้งหมด</span>
                      ) : (
                        <span>All News</span>
                      )}
                    </p>
                  </div>
                )}

                {props.page === "journals" && (
                  <div className="flex justify-end items-center gap-[18px] whitespace-nowrap">
                    <div className="w-full border-[1px] border-black my-[22px]" />
                    <div className="flex gap-[0px] items-center">
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[24px]"
                      />
                    </div>
                    <p
                      className="text-[18px] cursor-pointer hover:underline"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_WEB_URL}/service/journals`,
                          "_self"
                        );
                      }}
                    >
                      <span>วารสารทั้งหมด</span>
                    </p>
                  </div>
                )}

                <div className="flex justify-end mt-[15px]">
                  <div className="flex flex-col items-start justify-end w-fit">
                    <p className="mb-[10px] text-[18px]">Share : </p>
                    <div className="flex items-center justify-end gap-[25px]">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="transition hover:scale-110 delay-50 text-[40px] cursor-pointer text-[#3b5998]"
                        onClick={() => shareOnFacebook()}
                      />
                      <FontAwesomeIcon
                        icon={faLine}
                        className="transition hover:scale-110 delay-50 text-[40px] cursor-pointer text-[#06C755]"
                        onClick={() => shareOnLine()}
                      />
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(window.location.href);
                        }}
                        className="transition hover:scale-110 delay-50 cursor-pointer w-[45px] h-[45px] bg-lightGray hover:bg-lightGray rounded-full section text-[10px] p-[3px]"
                      >
                        Copy Link
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
