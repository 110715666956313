import http from "./../http-common";
import PrideNewsModel from "../../models/news-activity/pride-news";
import qs from "qs";

// eslint-disable-next-line
export default new (class PrideService {
  async getNurse(limit) {
    try {
      const query = `/prides?populate[0]=pride&populate[1]=pride.cover_image.image&populate[2]=pride.sample_image&populate[3]=pride.detail&populate[4]=pride.link&populate[5]=pride.drive&populate[6]=pride.tag&filters[pride][subcategory][$eq]=nurse_work&sort[0]=createdAt:desc`;
      const url = limit ? `${query}&pagination[limit]=${limit}` : query;

      const response = await http.get(url);
      const data = response.data.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getSocialActivity(limit) {
    try {
      const query =
        "/prides?populate[0]=pride&populate[1]=pride.cover_image&populate[2]=pride.sample_image&populate[3]=pride.detail&populate[4]=pride.link&populate[5]=pride.drive&populate[6]=pride.tag&filters[pride][category][$eq]=social_service&sort[0]=createdAt:desc";
      const url = limit ? `${query}&pagination[limit]=${limit}` : query;

      const response = await http.get(url);

      const data = response.data.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getPrideNews({
    keyword,
    startDate = null,
    endDate = null,
    sort = "desc",
    page = 1,
    pageSize = 5,
    category = null,
    subcategory = null,
  }) {
    const filters = {
      $or: [
        {
          pride: {
            event_TH: {
              $contains: keyword,
            },
          },
        },
        {
          pride: {
            event_EN: {
              $contains: keyword,
            },
          },
        },
        {
          pride: {
            tag: {
              tag: {
                $eq: keyword,
              },
            },
          },
        },
      ],
      $and: [],
    };

    if (subcategory && Array.isArray(subcategory) && subcategory.length > 0) {
      filters.$and.push({
        pride: {
          subcategory: {
            $in: subcategory,
          },
        },
      });
    }

    // Add date filters only if startDate or endDate is provided
    if (startDate) {
      filters.$and.push({
        publishedAt: {
          $gt: new Date(startDate).toISOString(),
        },
      });
    }

    if (endDate) {
      filters.$and.push({
        publishedAt: {
          $lt: new Date(endDate).toISOString(),
        },
      });
    }

    if (category === "nurse") {
      filters.$and.push({
        pride: {
          category: {
            $eq: "awards_workings",
          },
        },
      });
    } else if (category === "social") {
      filters.$and.push({
        pride: {
          category: {
            $eq: "social_service",
          },
        },
      });
    } else if (category === "gallery") {
      filters.$and.push({
        pride: {
          category: {
            $eq: "activity_gallery",
          },
        },
      });
    }

    // Build query with filters
    const query = qs.stringify(
      {
        sort: [`publishedAt:${sort}`],
        filters: filters,
        populate: "deep",
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return await http
      .get("/prides?" + query)
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((items) => PrideNewsModel.get(items))
        );

        const meta = response.data.meta;

        return [data, meta];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getPrideBySlug(slug) {
    return await http
      .get(`/prides?filters[slug][$eq]=${slug}`)
      .then(async (response) => {
        const id = response.data.data[0].id;

        const news = await http
          .get(`/prides/${id}?populate=deep`)
          .then(async (response) => {
            const data = await PrideNewsModel.get(response.data.data);
            return data;
          });

        return news;
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
