import React from "react";
import chevWhite from "../../assets/images/global/chev-right-white.svg";
import timeFunction from "../../functions/getTime";

export default function NewsCard(props) {
  return (
    <>
      {props.type === "pride" ? (
        <div
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_WEB_URL}/honor/awards/news/${props.data.slug}`,
              "_self"
            );
          }}
          className="dark-overlay cursor-pointer w-full h-[254px] border-[1px] border-lightGray2 rounded-[12px] items-start flex flex-col justify-end p-[18px] !bg-center !bg-cover"
          style={
            props.data?.image && props.data?.image[0]
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        >
          <p
            onClick={() => {
              if (props.page === "newsAndActivity/news")
                window.open(
                  `${process.env.REACT_APP_WEB_URL}/${props.page}/${props.data.slug}`,
                  "_self"
                );
            }}
            className={
              `z-[2] capitalize cursor-pointer text-[18px] xl:text-[22px] text-white font-medium text-left ` +
              (props.lineClamp
                ? `line-clamp-${props.lineClamp}`
                : "line-clamp-2")
            }
          >
            {props.lang === "th"
              ? props.data?.title?.detail_TH
              : props.data?.title?.detail_EN}
          </p>
          <div className="z-[2] flex justify-between items-center mt-[9px] w-full">
            <h1 className="text-[12px] xl:text-[14px] text-white font-medium text-left">
              {props.data?.publishedAt
                ? timeFunction.getTime(props.data.publishedAt, props.lang)
                : "ติดตามเร็วๆ นี้..."}
            </h1>
            {!props.hideIcon && (
              <img className="w-[18px] cursor-pointer" src={chevWhite} alt="" />
            )}
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            if (props.page === "newsAndActivity/news")
              window.open(
                `${process.env.REACT_APP_WEB_URL}/${props.page}/${props.data.slug}`,
                "_self"
              );
          }}
          className="dark-overlay cursor-pointer w-full h-[254px] border-[1px] border-lightGray2 rounded-[12px] items-start flex flex-col justify-end p-[18px] !bg-center !bg-cover"
          style={
            props.data?.image && props.data?.image[0]
              ? {
                  background: `url(${process.env.REACT_APP_API_URL}${props.data.image[0].attributes.url})`,
                }
              : { background: `#D9D9D9` }
          }
        >
          <p
            onClick={() => {
              if (props.page === "newsAndActivity/news")
                window.open(
                  `${process.env.REACT_APP_WEB_URL}/${props.page}/${props.data.slug}`,
                  "_self"
                );
            }}
            className={
              `z-[2] capitalize cursor-pointer text-[18px] xl:text-[22px] text-white font-medium text-left ` +
              (props.lineClamp
                ? `line-clamp-${props.lineClamp}`
                : "line-clamp-2")
            }
          >
            {props.lang === "th"
              ? props.data?.title?.detail_TH
              : props.data?.title?.detail_EN}
          </p>
          <div className="z-[2] flex justify-between items-center mt-[9px] w-full">
            <h1 className="text-[12px] xl:text-[14px] text-white font-medium text-left">
              {props.data?.publishedAt
                ? timeFunction.getTime(props.data.publishedAt, props.lang)
                : "ติดตามเร็วๆ นี้..."}
            </h1>
            {!props.hideIcon && (
              <img className="w-[18px] cursor-pointer" src={chevWhite} alt="" />
            )}
          </div>
        </div>
      )}
    </>
  );
}
