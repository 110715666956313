import React, { useState, Fragment } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

export default function FAQ(props) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <div className="border-[1px] rounded-[12px] py-[14px] px-[20px] border-lightGray2">
      <Fragment>
        {props.data &&
          props.data.map((item, i) => (
            <Accordion key={i} open={open === i + 1} animate={customAnimation}>
              <AccordionHeader
                onClick={() => handleOpen(i + 1)}
                className="font-medium text-black text-left ibm-plex-san faq-title flex flex-col items-start"
              >
                <h1
                  className="!text-brown text-[18px] xl:text-[20px]"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_WEB_URL}/newsAndActivity/news/${item.slug}`,
                      "_self"
                    );
                  }}
                >
                  {props.lang === "th"
                    ? item.title?.detail_TH
                    : item.title?.detail_EN}
                </h1>
                <h1 className="text-[16px] xl:text-[18px]">
                  {props.lang === "th"
                    ? item.description?.detail_TH
                    : item.description?.detail_EN}
                </h1>
              </AccordionHeader>
              <AccordionBody className="text-left ibm-plex-san text-[14px] xl:text-[18px] faq-body font-medium">
                <div
                  className="content-html text-left font-normal"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.lang === "th"
                        ? item.content[0]?.body_TH
                        : item.content[0]?.body_EN,
                  }}
                ></div>
              </AccordionBody>
            </Accordion>
          ))}
      </Fragment>
    </div>
  );
}
