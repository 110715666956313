// eslint-disable-next-line
export default new (class ActivityNewsModel {
    // static API_URL = process.env.REACT_APP_API_URL;
  
    async get(data) {
      const model = {};
  
      model.id = data.id || 0;
      model.slug = data.attributes.slug || "";
  
      model.title = {
        detail_EN: data.attributes.activity.event_EN || "",
        detail_TH: data.attributes.activity.event_TH || "",
      };
  
      model.description = {
        detail_EN: data.attributes.activity.detail?.detail_EN || "",
        detail_TH: data.attributes.activity.detail?.detail_TH || "",
      };
  
      model.tag = data.attributes.activity.tag?.map((tag) => tag.tag) || [];
  
      if (
        data.attributes.activity.cover_image.length > 0 &&
        data.attributes.activity.cover_image[0].image.data
      ) {
        model.image = data.attributes.activity.cover_image[0].image.data;
      } else {
        model.image = "";
      }

      if (
        data.attributes.activity.sample_image.length > 0 &&
        data.attributes.activity.sample_image[0].image.data
      ) {
        model.subImage = data.attributes.activity.sample_image[0].image.data;
      } else {
        model.subImage = "";
      }
  
      model.content = data.attributes.activity || {};
      model.createdAt = data.attributes.createdAt || "";
      model.publishedAt = data.attributes.publishedAt || "";
  
      return model;
    }
  })();
  