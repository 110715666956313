import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

export default function SwiperContent(props) {
  return (
    <div id="swiper-content">
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        scrollbar={{ draggable: true }}
        className="mySwiper"
      >
        {props.data.map((item, i) => (
          <SwiperSlide key={i}>
            <div
              className="dark-overlay min-h-[300px] sm:min-h-[480px] flex justify-center items-center border-b border-lightGray mb-[40px] !bg-cover"
              style={{
                background: `url(${process.env.REACT_APP_API_URL}${item.image}), #D9D9D9`,
              }}
            >
              {/* <h1>Slide {i + 1}</h1> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
