import React, { useEffect, useState } from "react";
// import AllButton from "../../components/button/AllButton";
import TextBanner from "../../components/banner/TextBanner";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";
// import { useParams } from "react-router-dom";
import { newsSize, scholarshipSize, meetingSize } from "../../configs/pageSize";
import Datepicker from "react-tailwindcss-datepicker";
import ScholarshipCard from "../../components/card/ScholarshipCard";

import NewsService from "../../services/news-activity/news";
import { currentLang } from "../../configs/lang";
import { useLocation } from "react-router-dom";

export default function NewsSearch(props) {
  let [newsData, setNewsData] = useState([]);
  let [meta, setMeta] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let [query, setQuery] = useState(searchParams.get("query"));
  const [selectedCategory, setSelectedCategory] = useState([]);

  const categoriesMap = {
    scholarship: [
      { name: "ข่าวทุนการศึกษา", value: "scholarship" },
      { name: "ข่าวต่างประเทศ", value: "global_news" },
      { name: "ทุนวิจัย", value: "research_funding" },
    ],
    meeting: [
      { name: "การประชุมวิชาการ", value: "academic_meeting" },
      { name: "งานสัมมนา", value: "seminar" },
      { name: "การอบรม", value: "training" },
    ],
    news: [
      { name: "ข่าวประชาสัมพันธ์", value: "annoucement" },
      { name: "ข่าวต่างประเทศ", value: "global_news" },
      { name: "ข่าวสารทั่วไป", value: "regular_news" },
    ],
  };

  const currentCategories = categoriesMap[props.page] || [];

  const handleCategoryClick = (value) => {
    setSelectedCategory((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  let lang = currentLang;

  const [page, setPage] = useState(1);

  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(
    props.page === "news"
      ? newsSize
      : props.page === "meeting"
      ? meetingSize
      : scholarshipSize
  );
  const [keyword, setKeyword] = useState("");
  // eslint-disable-next-line
  const [sort, setSort] = useState("desc");

  const [date, setDate] = useState({
    startDate: new Date(
      new Date().getFullYear() - 4,
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59,
      999
    )
      .toISOString()
      .slice(0, 10),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59,
      999
    ).toISOString(),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (query) {
      handleKeywordChange(query);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    getNewsData();

    // eslint-disable-next-line
  }, [keyword, date, sort, page, pageSize, selectedCategory]);

  const getNewsData = async () => {
    const startDateObj = new Date(date.endDate);
    startDateObj.setHours(0, 0, 0, 0);
    const endDateObj = new Date(startDateObj.getTime() + 86399999);
    const endDate = endDateObj.toISOString();
    let category = null;

    if (props.page === "news") {
      category = "news";
    } else if (props.page === "meeting") {
      category = "meetingseminar";
    } else if (props.page === "scholarship") {
      category = "scholarships";
    }

    try {
      let [res, meta] = await NewsService.getNews({
        ...(keyword && { keyword: keyword }),
        ...(query && { keyword: query }),
        startDate: date.startDate,
        endDate: endDate,
        sort: sort,
        page: page,
        pageSize: pageSize,
        category: category,
        subcategory: selectedCategory,
        isSubArray: true,
      });

      setNewsData(res);
      setMeta(meta);
    } catch {
      console.error();
    }
  };

  const handleKeywordChange = (newKeyword) => {
    setQuery(null);
    setKeyword(newKeyword);
  };

  const handleDateChange = (newValue) => {
    setDate(newValue);
  };

  const bannerText = () => {
    if (props.page === "news")
      return [
        { name: "news & activity", href: "/newsAndActivity" },
        { name: "News", href: "/newsAndActivity/news" },
      ];
    else if (props.page === "meeting")
      return [
        { name: "news & activity", href: "/newsAndActivity" },
        { name: "Meeting/Seminar", href: "/newsAndActivity/meeting" },
      ];
    else if (props.page === "scholarship")
      return [
        { name: "news & activity", href: "/newsAndActivity" },
        { name: "Scholarships", href: "/newsAndActivity/scholarship" },
      ];
    return [];
  };

  const pageText = () => {
    if (props.page === "news")
      return [
        {
          text_th: "ข่าวทั่วไป",
          text_en: "News",
          title_th: "ข่าวสารและประชาสัมพันธ์",
          title_en: "News and Public Relations",
        },
      ];
    else if (props.page === "meeting")
      return [
        {
          text_th: "ประชุม/สัมมนา/อบรม",
          text_en: "Apply Meeting/Seminar/Trainning",
          title_th: "ข่าวประชุมวิชาการ , สัมมนา และ การอบรม",
          title_en: "Academic conference news, seminars and trainings",
        },
      ];
    else if (props.page === "scholarship")
      return [
        {
          text_th: "ข่าวทุนการศึกษา",
          text_en: "Scholarships",
          title_th: "ข่าวทุนการศึกษา",
          title_en: "scholarship news",
        },
      ];
    return [];
  };

  return (
    <div id="news-all" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
      <TextBanner
        text_th={pageText()[0].text_th}
        text_en={pageText()[0].text_en}
        bannerText={bannerText()}
        lang={lang}
      />

      <div className="section">
        <div className="page-container">
          {/* ข่าวสาร */}
          <div id="news-section" className="pt-[54px] pb-[48px]">
            <h1 className="text-[28px] xl:text-[32px] text-left capitalize">
              {lang === "th" ? pageText()[0].title_th : pageText()[0].title_en}
            </h1>

            <div className="my-[20px] flex flex-col items-start justify-between gap-[20px] lg:gap-[40px]">
              <div className="w-full gap-[20px] flex flex-col items-center lg:flex-row">
                <div className="relative w-full">
                  <input
                    defaultValue={keyword}
                    onChange={(e) => handleKeywordChange(e.target.value)}
                    placeholder={
                      lang === "th" ? "ค้นหาจาก Keyword" : "Finding by Keyword"
                    }
                    className="w-full font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-[25px] cursor-pointer absolute text-darkGray top-[12px] right-[15px] pointer-events-none"
                  />
                </div>
                <div className="flex items-center gap-[50px] w-full">
                  <h1 className="text-[20px] whitespace-nowrap">
                    {lang === "th" ? "ช่วงเวลา" : "Time Range"}
                  </h1>
                  <div className="date-picker relative w-full">
                    <Datepicker value={date} onChange={handleDateChange} />
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-[25px] cursor-pointer absolute text-darkGray top-[14px] left-[15px] pointer-events-none"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-col lg:flex-row items-center gap-[20px] w-full justify-between`}
              >
                <div>
                  <p className="text-[16px] xl:text-[18px] flex items-center gap-[22px] flex-wrap">
                    หมวดหมู่ :{" "}
                    <span className="flex items-center gap-[22px] flex-wrap">
                      {currentCategories.map((category) => (
                        <span
                          key={category.value}
                          className={
                            `text-brown cursor-pointer ` +
                            (selectedCategory.includes(category.value)
                              ? "underline text-gold"
                              : "")
                          }
                          onClick={() => handleCategoryClick(category.value)}
                        >
                          {category.name} {/* Display the name */}
                        </span>
                      ))}
                    </span>
                  </p>
                </div>
                <div className="flex items-center gap-[50px]">
                  <h1 className="text-[20px] whitespace-nowrap">
                    {lang === "th" ? "เรียงลำดับ" : "Sort By"}
                  </h1>
                  <select
                    name="ordering"
                    className="w-fit font-normal text-[20px] py-[11px] px-[23px] border-[1px] border-darkGray rounded-[8px]"
                    value={sort}
                    onChange={(event) => setSort(event.target.value)}
                  >
                    <option value="desc">
                      {lang === "th" ? "ล่าสุด - เก่าสุด" : "Latest to Oldest"}
                    </option>
                    <option value="asc">
                      {lang === "th" ? "เก่าสุด - ล่าสุด" : "Oldest to Lastest"}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-[18px] mt-[38px]">
              {newsData?.map((item, i) => (
                <ScholarshipCard
                  data={item}
                  key={i}
                  lang={lang}
                  page="newsAndActivity/news"
                  isImageBox
                />
              ))}
            </div>

            {newsData && newsData.length <= 0 && (
              <p className="text-[23px] text-center mt-[40px]">
                {lang === "th"
                  ? "ไม่พบข้อมูลที่ค้นหา..."
                  : "Not found any news ..."}
              </p>
            )}

            {
              <Pagination
                length={meta?.pagination?.pageCount ?? ""}
                selected={page}
                function={(i) => setPage(i)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
