import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import HamburgerIcon from "hamburger-react";
import NavbarService from "../services/components/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/hamburger.css";
// import tel from "../assets/images/tel.svg";
// import globe from "../assets/images/globe.svg";
// import { useParams } from "react-router-dom";
import { currentLang } from "../configs/lang";

export default function Hamburger(props) {
  const [isOpen, setOpen] = useState(false);
  let [navbarData, setNavbarData] = useState([]);

  let lang = currentLang

  useEffect(() => {
    getNavbarData();
  }, []);

  const getNavbarData = async () => {
    try {
      let res = await NavbarService.getNavbar();
      setNavbarData(res);
    } catch {
      console.error();
    }
  };

  var isMenuOpen = function (state) {
    setOpen(state.isOpen);
  };

  const openDropdown = (i) => {
    const temp = navbarData.slice();

    const result = temp.map((e) => {
      if (e.id === i + 1) {
        return { ...e, active: !temp[i].active };
      } else {
        return e;
      }
    });

    setNavbarData(result);
  };

  // function switchLang() {
  //   const currentPath = window.location.pathname;
  //   const currentLanguage = currentPath.split("/")[1]; // get the current language code from the path
  //   let newLanguage;
  //   if (currentLanguage === "en") {
  //     newLanguage = "th";
  //   } else if (currentLanguage === "th") {
  //     newLanguage = "en";
  //   }
  //   let newPath;
  //   if (currentPath.endsWith(`/${currentLanguage}`)) {
  //     // if the current path ends with "/en" or "/th", replace it with the new language code
  //     newPath = currentPath.replace(`/${currentLanguage}`, `/${newLanguage}`);
  //   } else {
  //     // otherwise, replace the language code in the middle of the path
  //     newPath = currentPath.replace(`/${currentLanguage}/`, `/${newLanguage}/`);
  //   }
  //   window.location.href = window.location.origin + newPath;
  // }

  return (
    <div className="block lg:hidden">
      <Menu
        id="hamburger-menu"
        onStateChange={isMenuOpen}
        right
        customBurgerIcon={<HamburgerIcon toggled={isOpen} />}
      >
        <div className="!flex flex-col mt-[25px]">
          {navbarData &&
            navbarData.map((item, i) => (
              <span key={i} className="flex flex-col">
                <div
                  className={`flex gap-[5px] items-center py-[15px] px-[30px] border border-slate-500`}
                  onClick={() => {
                    openDropdown(i);
                  }}
                >
                  <div
                    className={
                      item.link && item.link === "-"
                        ? "pointer-events-none"
                        : undefined
                    }
                  >
                    <h1
                      id="home"
                      className="cursor-pointer menu-item font-normal text-[20px] text-black uppercase text-left"
                      href="/"
                    >
                      {lang === "th" ? item.name_th : item.name_en}
                    </h1>
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={
                      "transition duration-200 h-[14px] w-[14px] cursor-pointer text-black " +
                      (item.active ? "-rotate-90" : null)
                    }
                  />
                </div>

                {item.active && (
                  <ul className="pl-[60px] pb-[20px] pt-[15px] list-disc flex flex-col gap-[20px] border border-slate-500">
                    <div
                      onClick={() => {
                        if (item.link)
                          window.open(
                            `${process.env.REACT_APP_WEB_URL}${item.link}`,
                            "_self"
                          );
                        props.closeDropdown();
                      }}
                    >
                      <li className="text-left text-[16px] cursor-pointer capitalize whitespace-nowrap text-black">
                        {lang === "th" ? item.name_th : item.name_en}
                      </li>
                    </div>
                    {item.subMenu.map((item, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          if (item.href)
                            window.open(
                              `${process.env.REACT_APP_WEB_URL}${item.href}`,
                              "_self"
                            );
                          props.closeDropdown();
                        }}
                      >
                        <li className="text-left text-[16px] cursor-pointer capitalize whitespace-nowrap text-black">
                          {lang === "th" ? item.label_TH : item.label_EN}
                        </li>
                      </div>
                    ))}
                  </ul>
                )}
              </span>
            ))}
          <div>
            {/* <div className="py-[15px] px-[30px] flex items-center justify-start bg-darkGray3 gap-[16px]">
              <img className="w-[23px]" src={tel} alt="" />
              {props.data && props.data[1] && (
                <p className="text-[18px] xl:text-[20px] text-white">
                  {lang === "th"
                    ? props.data[1].detail.detail_TH
                    : props.data[1].detail.detail_EN}
                </p>
              )}
            </div> */}

            {/* <div
              className="cursor-pointer py-[15px] px-[30px] flex items-center justify-start bg-darkGray3 gap-[9px]"
              onClick={() => {
                switchLang();
              }}
            >
              <img className="w-[25px]" src={globe} alt="" />
              <p className="text-[16px] xl:text-[18px] text-white">
                {lang === "th" ? "TH" : "EN"}
              </p>
            </div> */}
          </div>
        </div>
      </Menu>
    </div>
  );
}
