import React from "react";
// import timeFunction from "../../functions/getTime";
import getVideoImage from "../../functions/getImage";
import timeFunction from "../../functions/getTime";

export default function ImageCard(props) {
  function openLink() {
    if (props.data && props.isVideo) {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/video/${props.data.slug}`,
        "_self"
      );
    } else {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/honor/gallery/${props.data.slug}`,
        "_self"
      );
    }
  }

  return (
    <>
      {props.type === "pride" ? (
        <div className="image-card pb-[40px] lg:pb-[0px]">
          {props.isVideo !== true ? (
            <div className="relative ml-[40px]" onClick={() => openLink()}>
              <div
                className="cursor-pointer z-20 absolute top-[-40px] right-[40px] h-[200px] w-full rounded-[12px] bg-lightGray !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.image &&
                    `url(${process.env.REACT_APP_API_URL}${props.image.url}), #D9D9D9`,
                }}
              />
              <div
                className="cursor-pointer z-10 absolute top-[-20px] right-[20px] h-[200px] w-full rounded-[12px] bg-lightGray2 !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.subImage[0] &&
                    `url(${process.env.REACT_APP_API_URL}${props.subImage[0]?.attributes.url}), #AEAEAE`,
                }}
              />
              <div
                className="cursor-pointer z-30 h-[200px] rounded-[12px] bg-darkGray w-full !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.subImage[1] &&
                    `url(${process.env.REACT_APP_API_URL}${props.subImage[1]?.attributes.url}), #696969`,
                }}
              />
            </div>
          ) : (
            <div onClick={() => openLink()}>
              <div
                className="cursor-pointer h-[200px] rounded-[12px] bg-lightGray w-full !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    `url(${getVideoImage(props.data.video[0].href)}), #D9D9D9`,
                }}
              />
            </div>
          )}

          {props.data && (
            <p
              onClick={() => openLink()}
              className="cursor-pointer font-medium mt-[15px] text-[16px] xl:text-[18px] text-left line-clamp-2"
            >
              {props.lang === "th"
                ? props.data.title.detail_TH
                : props.data.title.detail_EN}
            </p>
          )}

          <p className="text-[10px] xl:text-[12px] text-left text-brown">
            {props.data &&
              timeFunction.getPrideData(props.data?.createdAt, props.lang)}
          </p>

          <p
            onClick={() => openLink()}
            className="cursor-pointer text-[8px] xl:text-[14px] font-bold leading-[14px] text-right text-red"
          >
            {props.lang === "th" ? "แสดงภาพ" : "See more"}
          </p>
        </div>
      ) : (
        <div className="image-card pb-[40px] lg:pb-[0px]">
          {props.isVideo !== true ? (
            <div className="relative ml-[40px]" onClick={() => openLink()}>
              <div
                className="cursor-pointer z-20 absolute top-[-40px] right-[40px] h-[200px] w-full rounded-[12px] bg-lightGray !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.image &&
                    `url(${process.env.REACT_APP_API_URL}${props.image.url}), #D9D9D9`,
                }}
              />
              <div
                className="cursor-pointer z-10 absolute top-[-20px] right-[20px] h-[200px] w-full rounded-[12px] bg-lightGray2 !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.subImage[0] &&
                    `url(${process.env.REACT_APP_API_URL}${props.subImage[0]?.image?.data[0]?.attributes.url}), #AEAEAE`,
                }}
              />
              <div
                className="cursor-pointer z-30 h-[200px] rounded-[12px] bg-darkGray w-full !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    props.subImage[1] &&
                    `url(${process.env.REACT_APP_API_URL}${props.subImage[1]?.image?.data[0]?.attributes.url}), #696969`,
                }}
              />
            </div>
          ) : (
            <div onClick={() => openLink()}>
              <div
                className="cursor-pointer h-[200px] rounded-[12px] bg-lightGray w-full !bg-cover !bg-center"
                style={{
                  background:
                    props.data &&
                    `url(${getVideoImage(props.data.video[0].href)}), #D9D9D9`,
                }}
              />
            </div>
          )}

          {props.data && (
            <p
              onClick={() => openLink()}
              className="cursor-pointer font-medium mt-[15px] text-[16px] xl:text-[18px] text-left line-clamp-2"
            >
              {props.lang === "th"
                ? props.data.activity.event_TH
                : props.data.activity.event_EN}
            </p>
          )}

          <p className="text-[10px] xl:text-[12px] text-left text-brown">
            {props.data?.activity &&
              timeFunction.getPrideData(props.data?.updatedAt, props.lang)}
          </p>

          <p
            onClick={() => openLink()}
            className="cursor-pointer text-[8px] xl:text-[14px] font-bold leading-[14px] text-right text-red"
          >
            {props.lang === "th" ? "แสดงภาพ" : "See more"}
          </p>
        </div>
      )}
    </>
  );
}
