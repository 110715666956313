import React from "react";
import timeFunction from "../../functions/getTime";

export default function AwardCard(props) {
  function isLinkPage() {
    if (props.data) {
      window.open(
        `${process.env.REACT_APP_WEB_URL}/honor/awards/news/${props.data.slug}`,
        "_self"
      );
    } else {
      window.open(`${process.env.REACT_APP_WEB_URL}`, "_self");
    }
  }

  return (
    <div className="flex flex-col gap-[15px]">
      <div
        onClick={() => isLinkPage()}
        className="cursor-pointer h-[260px] bg-lightGray rounded-[12px] !bg-cover !bg-center"
        style={{
          background: `url(${process.env.REACT_APP_API_URL}${props.image?.url}), #D9D9D9`,
        }}
      />
      <div className="flex flex-col gap-[5px] px-[15px]">
        <p
          className="cursor-pointer text-[16px] xl:text-[18px] text-left text-brown line-clamp-3"
          onClick={() => isLinkPage()}
        >
          {props.lang === "th"
            ? props.data?.pride?.event_TH
            : props.data?.pride?.event_EN}
        </p>
        <p className="text-[10px] xl:text-[12px] text-left text-brown">
          {props.data?.pride &&
            timeFunction.getPrideData(props.data?.publishedAt, props.lang)}
        </p>
      </div>
    </div>
  );
}
