import http from "../http-common";
import MemberModel from "../../models/member/member";

// eslint-disable-next-line
export default new (class MemberService {
  async searchMember(name, surname) {
    const filters = {
      "filters[name][$eq]": name,
      "filters[surname][$eq]": surname,
    };

    return await http
      .get("/members", { params: filters })
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((member) => MemberModel.get(member))
        );

        return data[0];
      })
      .catch((error) => {
        console.error(error);
      });
  }
})();
