import http from "../http-common";
import ViewsModel from "../../models/views/views";

// eslint-disable-next-line
export default new (class ViewsService {
  async getViews() {
    return await http
      .get("/views/1")
      .then(async (response) => {
        const data = await ViewsModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async updateViews() {
    try {
      const hasViewed = document.cookie.includes("viewed=true");

      if (!hasViewed) {
        const response = await http.get("/views/1");
        const currentData = await ViewsModel.get(response.data.data);

        const updateData = {
          data: {
            today_views: currentData.today_views + 1,
            this_month_views: currentData.this_month_views + 1,
            all_time_views: currentData.all_time_views + 1,
          },
        };

        const updateResponse = await http.put("/views/1", updateData);

        // If the update was successful, set the cookie
        if (updateResponse.status === 200) {
          document.cookie = "viewed=true; max-age=3600";
          return updateResponse.data;
        } else {
          // Handle the case where the update was not successful
          console.error("Error updating view counts:", updateResponse.statusText);
          // You might want to throw an error or handle this situation in another way
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, throw it, or log it as needed
    }
  }
})();
