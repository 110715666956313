import React, { useEffect, useState } from "react";
import AboutUsService from "../../services/about-us/about-us";
// import { useParams } from "react-router-dom";
import TextBanner from "../../components/banner/TextBanner";
import { currentLang } from "../../configs/lang";

// import DateParagraph from "../../components/content/DateParagraph";
// import BulletParagraph from "../../components/content/BulletParagraph";

export default function History() {
  let lang = currentLang
  let [historyData, setHistoryData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await AboutUsService.getHistoryData();
      setHistoryData(res);
    } catch {
      console.error();
    }
  };

  const bannerText = [
    { name: "About us", href: "/abouts" },
    { name: "History", href: "/abouts/history" },
  ];

  return (
    <>
      {historyData && (
        <div id="service" className="pt-[100px] sm:pt-[135px] lg:pt-[0px]">
          <TextBanner
            text_th={"ประวัติสมาคมฯ"}
            text_en={"History"}
            // banner={serviceBanner.banner}
            bannerText={bannerText}
            lang={lang}
          />

          <div className="section">
            <div className="page-container">
              <h1 className="text-[28px] xl:text-[32px] text-left mt-[75px]">
                {lang === "th"
                  ? historyData.content[0]?.title_TH
                  : historyData.content[0]?.title_EN}
              </h1>
              {historyData.content && (
                <div
                  className="content-html text-left my-[30px] font-normal"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "th"
                        ? historyData.content[0]?.body_TH
                        : historyData.content[0]?.body_EN,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
